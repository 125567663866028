import React from 'react';
import './ALCaseStudies.css';
import img1 from "../../AI/AICaseStudies/Assets/AICas1.jpeg";
import img2 from "../../Webapplication/CaseStudies/Assets/2.jpeg";
import img3 from "../../AI/AICaseStudies/Assets/AICase2.jpg";

const AICaseStudies = () => {
  return (
    <div className='container-fluid container_CaseStudy'id="CaseStudies">
      
      <div className='row'>
        <div className='col-12 heading text-center'>
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>

        <div className='row'>

        <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
            <div className="alll">
            <img
              src={img1}
              alt="img1"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_1 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
                </div>
              <h5>
              Artificial Intelligence in Healthcare
              </h5>
              <p>
              AI has the ability to change the healthcare system by producing new and crucial insights into the vast amount of digital data that it can access far more quickly and efficiently than any human can..
              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img2}
              alt="img2"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_2 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
                </div>
              <h5>
              Developing AI Services Using Market-leading Technologies

              </h5>
              <p>
              Artificial intelligence, or AI, is the theory and development of computer systems that are able to perform tasks normally required by human intelligence.
              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img3}
              alt="img3"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_3 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
                </div>
              <h5>
              SERVICES WE OFFER

              </h5>
              <p>
              From face recognition to object recognition that instantaneously analyse defects in industrial products, we provide AI services that integrate into your business with ease.
              </p>
            </div>
          </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default AICaseStudies;
