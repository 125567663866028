import React, { useEffect, useState } from "react";
import img1 from "../../Components/Header/Assests/1.jpg";
import "./Header.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { PopupButton } from "react-calendly";
import { FaBars } from "react-icons/fa"; // Import the menu icon

const Header = () => {

  const[menu,setmenu]=useState(false)

  useEffect(() => {
    AOS.init();
    document.querySelector('.Header_Overall0001').style.marginTop = '1vh';
  }, []);

  const togglmenu=()=>{
    setmenu(!menu)
  }

  return (
    <div className="container Header_Overall0001">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 d-flex smallmenu">
          
          {/* Adjusted Logo Section */}
          <div className="col-lg-3 col-md-4 col-sm-6 d-flex justify-content-center align-items-center one">
            <img src={img1} alt="" className="img-fluid w-100" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>

          {/* Icon for small screens */}
          <div className="menu-icon col-sm-6 col-md-4 d-lg-none order-md-3 
          rblock d-flex flex-row-reverse align-items-center three">
            <FaBars onClick={togglmenu}/>
              {menu &&
                           (
                            <div className="ablock">
                              <div className="vertical-menu">
                                <a className="dropdown-item" href="/">Home</a>
                                <a className="dropdown-item" href="/AboutUs">About Us</a>
              
                                <div class="dropdown d-md-dropstart">
                                <a href="#" class="dropdown-toggle dropdown-item" data-bs-toggle="dropdown" aria-expanded="false">
                                   Industries
                                  </a>

                                <ul class="dropdown-menu dropdown-menu-end d-md-dropdown-menu-start">
                                    <li><a className="dropdown-item" href="/Banking">Banking Finance and services</a></li>
                                    <li><a className="dropdown-item" href="/Insurance">Insurance</a></li>
                                    <li><a className="dropdown-item" href="/HealthCare">HealthCare</a></li>
                                    <li><a className="dropdown-item" href="/HT">Hi-Tech</a></li>
                                    <li><a className="dropdown-item" href="/Public">Public Sector</a></li>
                                    <li><a className="dropdown-item" href="/Telecom">TeleCommunication</a></li>
                                    <li><a className="dropdown-item" href="/ECommerce">E-Commerce</a></li>
                                    <li><a className="dropdown-item" href="/Retail">Retail & Wholesale</a></li>
                                    <li><a className="dropdown-item" href="/Global">Global Capability</a></li>
                                    <li><a className="dropdown-item" href="/Energy">Energy & Utilities</a></li>
                                </ul>
                              </div>

                              <div class=" dropdown">
                              <a href="#" class="dropdown-toggle dropdown-item" data-bs-toggle="dropdown" aria-expanded="false">
                                  Services
                                </a>

                                <ul class="dropdown-menu dropdown-menu-end">
                               <li> <a className="dropdown-item" href="/Webdevelopment">Web Development</a></li>
                                  <li><a className="dropdown-item" href="/Mobile">Mobile App</a></li>
                                  <li><a className="dropdown-item" href="/AI">AI Development</a></li>
                                  <li><a className="dropdown-item" href="/AppDevelopment"> App Development & Main </a></li>
                                  <li><a className="dropdown-item" href="/DataandAnalyst">Data & Analytics</a></li>
                                  <li><a className="dropdown-item" href="/Devops">DevOps</a></li>
                                  <li><a className="dropdown-item" href="/Testing">Testing Services</a></li>
                                  <li><a className="dropdown-item" href="/Digital">Digital Experience</a></li>
                                  <li><a className="dropdown-item" href="/Cloud">Cloud Security</a></li>
                                  <li><a className="dropdown-item" href="/Business">Business Process Management</a></li>
                                </ul>
                              </div>
                                            
                                <a className="dropdown-item" href="/Peoplecarear">Careers</a>
                                <a className="dropdown-item" href="/ContactUs1">Contact Us</a>
                              </div>
                            </div>
                          )}
          </div>

          {/* Menu Content */}
          <div
            className="Contantbox col-lg-7  d-flex flex-row d-none d-lg-flex"
            data-aos="fade-left"
          >
            <div className="link1">
              <a className="dropdown-item" href="/">
                Home
              </a>
            </div>
            <div className="link1">
              <a className="dropdown-item" href="/AboutUs">
                About Us
              </a>
            </div>
            <div className="dropdown">
              <a
                className="btn dropdown-toggle"
                href="#"
                role="button"
                id="companyDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Industries
              </a>
              <div className="dropdown-menu dropdown-menu-end" aria-labelledby="companyDropdown">
                <a className="dropdown-item" href="/Banking">Banking Finance and services</a>
                <a className="dropdown-item" href="/Insurance">Insurance</a>
                <a className="dropdown-item" href="/HealthCare">HealthCare</a>
                <a className="dropdown-item" href="/HT">Hi-Tech</a>
                <a className="dropdown-item" href="/Public">Public Sector</a>
                <a className="dropdown-item" href="/Telecom">TeleCommunication</a>
                <a className="dropdown-item" href="/ECommerce">E-Commerce</a>
                <a className="dropdown-item" href="/Retail">Retail & Wholesale</a>
                <a className="dropdown-item" href="/Global">Global Capability</a>
                <a className="dropdown-item" href="Energy">Energy & Utilities</a>
              </div>
            </div>
            <div className="dropdown">
              <a
                className="btn dropdown-toggle"
                href="#"
                role="button"
                id="companyDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Services
              </a>
              <div className="dropdown-menu dropdown-menu-end dzvisible" aria-labelledby="companyDropdown">
                <a className="dropdown-item" href="/Webdevelopment">Web Development</a>
                <a className="dropdown-item" href="/Mobile">Mobile App</a>
                <a className="dropdown-item" href="/AI">AI Development</a>
                <a className="dropdown-item" href="/AppDevelopment"> App Development & Maintenance </a>
                <a className="dropdown-item" href="/DataandAnalyst">Data & Analytics</a>
                <a className="dropdown-item" href="/Devops">DevOps</a>
                <a className="dropdown-item" href="/Testing">Testing Services</a>
                <a className="dropdown-item" href="/Digital">Digital Experience</a>
                <a className="dropdown-item" href="/Cloud">Cloud Security</a>
                <a className="dropdown-item" href="/Business">Business Process Management</a>
              </div>
            </div>
            <div className="link1">
              <a className="dropdown-item" href="/Peoplecarear">
                Careers
              </a>
            </div>
            <div className="link1">
              <a className="dropdown-item" href="/ContactUs1">
                Contact Us
              </a>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6 col-md-4 order-md-2 two d-flex align-items-center">
            <PopupButton
              url="https://calendly.com/dk-ldappsolutions"
              rootElement={document.getElementById("root")}
              text="BOOK  1 : 1 CALL "
              className="btn02"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
