import React from "react";
import "./DevopsOverview.css";

const DevopsOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="DGContent_Development21">
            <h1>DEVOPS AND AGILE</h1>

            <p>
              Integrating DevOps capabilities with Agile approach to shorten the
              development
              <br /> lifecycle and bring better flexibility, collaboration, and
              customer satisfaction
            </p>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-12 my-3">
          <div className="DGButton_Contain">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Capabilities")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Capabilities
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Tools")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Tools
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("CaseStudies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
          </div>
        </div>
      </div>
      <div className="DGtext-info">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-lg-12 my-5 DGOverview_content">
          <p>
            At LDAPP SOLUTIONS, we prioritize combining the Agile sprint model with the
            collaborative teamwork enabled by DevOps. This approach allows our
            clients to optimize their products incrementally throughout the
            development lifecycle, ensuring efficient maintenance.
          </p>
          <p>
            An example of the successful implementation of this strategy can be
            seen in a Dutch banking and financial services company. By creating
            cross-functional teams, implementing continuous integration and
            delivery, and utilizing automated testing to improve software
            quality, the company was able to reduce their time-to-market from
            several months to just a few weeks.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DevopsOverview;
