import React from "react";
import "./VedioHome.css";
import vedio2 from "../VedioHome/Assests/vedio1.mp4";

const VedioHome = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="player_wrapper01">
            <video className="react_player01" src={vedio2} loop autoPlay muted>
              Your browser does not support HTML video.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VedioHome;
