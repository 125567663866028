import React from "react";
import "./Capabilities.css";
import img1 from "../../Webapplication/Capabilities/Assets/01.jpg";
import img2 from "../../Webapplication/Capabilities/Assets/02.jpg";
import img3 from "../../Webapplication/Capabilities/Assets/03.jpg";
import img4 from "../../Webapplication/Capabilities/Assets/04.jpg";

const Capabilities = () => {
  return (
    <div className="container-fluid " id="Capabilities">
      <div className="row">
        <div className="col-lg-12">
          <div>
            <h1>
              Transform your business with high-quality web development
              solutions
            </h1>
          </div>
        </div>
        <div className="col-lg-12 webService_Content_01 my-5 ">
          <div className="col-lg-7 mx-3">
            <div className="webServices_content01">
              <h3>
                Expert custom web application development services for unique
                needs
              </h3>
              <p>
                <p>
                  <h3>Core Services :</h3>
                </p>
                Web Application Development |eCommerce Development| Digital
                Marketing| UI/UX Design Website Development| Digital
                Transformation| Resource Outsourcing Maintenance & Support
                SharePoint Customization| Corporate Video Production RFID
                Solutions Branding & Strategy Security Solutions| Mobile
                Application Development |Testing Offshore Software Development
                |Website Designing Cross platform API Integration| Software
                Development Digital Marketing Consulting Services.
              </p>
              <hr />
              <ul className="webicon_li">
                <li className="weblist-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> Application
                  development consulting
                </li>
                <li className="weblist-group-item">
                  <i className="bi bi-emoji-laughing"></i> Custom web
                  application development
                </li>
                <li className="weblist-group-item">
                  <i className="bi bi-emoji-smile"></i>Ecommerce application
                  development
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 webImage_service01">
            <img src={img1} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-12 webService_Content_02 ">
          <div className="col-lg-5 webImage_service02">
            <img src={img4} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="webServices_content02 ">
              <h3>
                Transform your business with high-quality custom web development
                solutions
              </h3>
              <p>
                Our developers constantly strive to provide you with a web
                application that is technically strong with solid design,
                well-coded functions, and robust security features.
                <br />
                We focus on custom web application development to transform
                businesses. We become tech partners of businesses to provide
                them seamless service by customizing there applications. At
                LDAPP we offer high-quality customized web development solutions
                with lightweight bug-free code and user-friendly web design
                while simultaneously integrating complex business workflow with
                a simplistic design in your web applications.
              </p>
              <hr />
              <ul className="webicon_li">
                <li className="weblist-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> Dedicated project
                  manager
                </li>
                <li className="weblist-group-item">
                  <i className="bi bi-emoji-laughing"></i>
                  Application development consultation
                </li>
                <li className="weblist-group-item">
                  <i className="bi bi-emoji-smile"></i> Mobile application
                  development
                </li>
                <li className="weblist-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> Support; security &
                  scalability
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-12 webService_Content_01 my-5">
          <div className="col-lg-7 mx-3">
            <div className="webServices_content01">
              <h3>APPLICATION MODERNIZATION</h3>
              <p>
                Driving application modernization changes to provide your
                business competitive edge LDAPP, a custom web application
                development company understands your business process, needs,
                and objectives and helps you automate the business flow with our
                customized web application solution developed just for your
                business needs according to the recent market trends.
              </p>
              <hr />
              <ul className="webicon_li">
                <li className="weblist-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>
                  Cloud application development
                </li>
                <li className="weblist-group-item">
                  <i className="bi bi-emoji-laughing"></i> Application support &
                  maintenance
                </li>
                <li className="weblist-group-item">
                  <i className="bi bi-emoji-smile"></i>Application migration or
                  re-engineering
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 webImage_service01">
            <img src={img3} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-12 webService_Content_02 ">
          <div className="col-lg-5 webImage_service02">
            <img src={img2} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="webServices_content02 ">
              <h3> LDAPP SOLUTIONS technology partner</h3>
              <p>
                Redesigning your business ideas to cutting-edge, robust, and
                highly scalable custom web applications to escalate your
                business growth. Our dedicated team of professional designers
                has expertise in a wide range of technologies and constantly
                keeps updating themselves to provide the latest and most
                advanced web applications trending in the competitive market. We
                know what makes an application better than the best, so we put
                into work several updated frameworks, databases, APIs, and
                updated technologies to give our clients leverage in their
                respective markets.
              </p>
              <hr />
              <ul className="webicon_li">
                <li className="weblist-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>Expertise in
                  customized IT Solutions and Digital transformation
                </li>
                <li className="weblist-group-item">
                  <i className="bi bi-emoji-laughing"></i> Experienced
                  development team with training and upscaling
                </li>
                <li className="weblist-group-item">
                  <i className="bi bi-emoji-smile"></i> Consulting, Development,
                  DevOps, support in-house team
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Capabilities;
