import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="container-fluid containerall py-5">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-center align-items-between flex-wrap py-3">

          {/* Company Section */}
          <div className="col-lg-2 col-md-4 col-sm-6 col-12 content_11 py-3">
            <ul className="content_11ul">
              <h5>Company</h5>
              <li className="list-group-item"><a href="/AboutUs">About Us</a></li>
              <li className="list-group-item"><a href="/Peoplecarear">Careers</a></li>
            </ul>
          </div>

          {/* Industries Section */}
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 content_11 py-3">
            <ul className="content_11ul">
              <h5>Industries</h5>
              <li className="list-group-item"><a href="/Banking">Banking & Finance</a></li>
              <li className="list-group-item"><a href="/Insurance">Insurance</a></li>
              <li className="list-group-item"><a href="/HealthCare">HealthCare</a></li>
              <li className="list-group-item"><a href="/HT">Hi-Tech</a></li>
              <li className="list-group-item"><a href="/Public">Public Sector</a></li>
              <li className="list-group-item"><a href="/Telecom">TeleCommunication</a></li>
              <li className="list-group-item"><a href="/ECommerce">E-Commerce</a></li>
              <li className="list-group-item"><a href="/Retail">Retail & Wholesale</a></li>
              <li className="list-group-item"><a href="/Global">Global Capability</a></li>
              <li className="list-group-item"><a href="/Energy">Energy & Utilities</a></li>
            </ul>
          </div>

          {/* Services Section */}
          <div className="col-lg-4 col-md-4 col-sm-6 col-12 content_11 py-3">
            <ul className="content_11ul">
              <h5>Services</h5>
              <li className="list-group-item"><a href="/Webdevelopment">Web Enterprise Application Development</a></li>
              <li className="list-group-item"><a href="/Mobile">Mobile Application Development</a></li>
              <li className="list-group-item"><a href="/AI">Artificial intelligence Application Development</a></li>
              <li className="list-group-item"><a href="#">App Development & Maintenance</a></li>
              <li className="list-group-item"><a href="DataandAnalyst">Data & Analytics</a></li>
              <li className="list-group-item"><a href="/Devops">DevOps and Agile</a></li>
              <li className="list-group-item"><a href="/Testing">Testing Services</a></li>
              <li className="list-group-item"><a href="/Digital">Digital Customer Experience</a></li>
              <li className="list-group-item"><a href="/Cloud">Cloud Security</a></li>
              <li className="list-group-item"><a href="/Business">Business Process Management</a></li>
            </ul>
          </div>

          {/* Corporate Address Section */}
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 content_11 py-3">
            <h5>Corporate Address</h5>
            <div className="dropdown">
              <a
                className="btn dropdown-toggle"
                href="#"
                role="button"
                id="companyDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Bangalore
              </a>
              <div className="dropdown-menu content_11ula" aria-labelledby="companyDropdown">
                <a className="dropdown-item" href="#">
                  Novel Office, First Floor, <br />
                  Brigade Tech Park,<br />
                  ITPL Main road,<br />
                  Pattandhur Agraghara,<br />
                  Whitefield.<br />
                  Bangalore-560066
                </a>
              </div>
            </div>

            <div className="dropdown">
              <a
                className="btn dropdown-toggle"
                href="#"
                role="button"
                id="companyDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Chennai
              </a>
              <div className="dropdown-menu content_11ula" aria-labelledby="companyDropdown">
                <a className="dropdown-item" href="#">
                  Ground Floor, 113,114 <br />
                  TEK Towers, Rathha IT PARK,<br /> Thoraippakam,<br />
                  Old Mahabalipuram Road,<br />
                  Chennai-600097
                </a>
              </div>
            </div>

            <div className="dropdown">
              <a
                className="btn dropdown-toggle"
                href="#"
                role="button"
                id="companyDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Singapore
              </a>
              <div className="dropdown-menu content_11ula" aria-labelledby="companyDropdown">
                <a className="dropdown-item" href="#">
                  The Metropolis Tower Two<br />
                  Level 9, The Metropolis Tower 2 <br />
                  11 North Buona Vista Drive,<br />
                  Singapore, 138589<br />
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="row">
        <div className="col-12 d-flex flex-column flex-sm-row justify-content-between align-items-center">
          
          {/* Footer Text */}
          <div className="col-12 col-sm-8 text-center text-sm-right d-flex justify-content-end">
            <p className="Tuni">@ 2018-2024, LDAPP Solutions Private Limited</p>
          </div>

          {/* Social Media Icons */}
          <div className="col-12 col-sm-4 d-flex justify-content-center justify-content-sm-end iconall">
            <a href="https://www.linkedin.com/company/ldapp-solutions/?viewAsMember=true" target="_block"><i className="bi bi-linkedin"></i></a>
            <a href="https://web.whatsapp.com/" target="_block"><i className="bi bi-whatsapp"></i></a>
            <a href="https://x.com/LdappSolutions" target="_block"><i className="bi bi-twitter-x"></i></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
