import React from "react";
import "./MobileOverview.css";

const MobileOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="mobContent_Development18">
            <h1>MOBILE APPLICATION DEVELOPMENT </h1>
            <p>
            Product Development
            </p>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-12 my-3">
          <div className="mobButton_Contain">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Capabilities")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Capabilities
            </button>
            <button
             onClick={() => {
              document
                .getElementById("Tools")
                .scrollIntoView({ behavior: "smooth" });
            }}>Tools</button>
            <button
             onClick={() => {
              document
                .getElementById("CaseStudies")
                .scrollIntoView({ behavior: "smooth" });
            }}>Case Studies</button>
            <button
             onClick={() => {
              document
                .getElementById("Blogs")
                .scrollIntoView({ behavior: "smooth" });
            }}>Blogs</button>
          </div>
        </div>
      </div>
      <div className="text-info">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-lg-12 my-5 mobOverview_content">
          <p>
          Our Application Development and Maintenance services provide end-to-end solutions, from initial design and development to ongoing support and enhancement. We specialize in creating custom applications that align with your business objectives, ensuring they are secure, scalable, and efficient.
        </p>
          <p>
          We also offer comprehensive maintenance services to keep your applications running smoothly, ensuring they remain up-to-date with the latest features and performance improvements. Whether you need to develop a new application from scratch or maintain and enhance existing ones, our services are designed to support your business's growth and success.          </p>
        </div>
      </div>
    </div>
  );
};

export default MobileOverview;
