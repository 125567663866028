import React from "react";
import "./InsuranceService.css";

const InsuranceService = () => {
  return (
    <div className="container" id="Services">
      <div className="row">

        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 BankServices_Content">
            <h3>DIGITAL SERVICES FOR RETAIL</h3>
            <p>
              We collaborate closely with clients across the insurance industry
              to help them meet today’s challenges while preparing for what’s
              ahead.
            </p>
            <p>
              Many leading Insurance companies are turning to LDAPP for help
              in exploiting the potential of digital technologies to make
              customers the pivot of their businesses and to aim for growth and
              operational excellence.
            </p>
            <p>
              We specialize in providing data, testing, cloud, CX, and
              application development services to the insurance industry.
            </p>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="row d-flex flex-row">
            
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="mobile1 p-3 d-flex flex-column justify-content-center align-items-center">
                <h3 className="txt_01">INSURANCE ANALYTICS</h3>
                <p className="txt_02">
                  Proven and reusable data management and decision analytics to
                  gain deeper insights
                  <br />
                </p>
              </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="mobile2 p-3 d-flex flex-column justify-content-center align-items-center">
                <h3 className="txt_01">DIGITAL FIRST OPERATIONS</h3>
                <p className="txt_02">
                  Transform the operations through omni-channel solutions,
                  portals and custom build applications
                  <br />
                </p>
              </div>
            </div>
              
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="mobile4 p-1 d-flex flex-column justify-content-center align-items-center">
                <h3 className="txt_01">
                  CX DRIVEN UNDERWRITING AND CLAIMS MANAGEMENT
                </h3>
                <p className="txt_02">
                  Build trust and loyalty, and ultimately drive business success
                  for the insurer.
                  <br />
                </p>
              </div>
              </div>


              <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="mobile1 p-3 d-flex flex-column justify-content-center align-items-center">
                <h3 className="txt_01">END TO END TESTING</h3>
                <p className="txt_02">
                  Improving the overall quality and reliability of the systems
                  and processes
                  <br />
                </p>
              </div>
            </div>

           </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className=" BankServices_Content">
            <p>
              We also have expertise in cloud computing and can help insurance
              companies to move their operations to the cloud to improve
              scalability, reduce costs, and increase efficiency.
            </p>
            <p>
              Our team of experts has years of experience working with insurance
              companies to help them optimize their operations and improve their
              overall performance. In terms of data, we help insurance companies
              to collect, organize, and analyze their data to gain valuable
              insights and make informed business decisions. We also offer
              testing services to ensure that applications and systems are
              functioning properly and meeting the needs of the business.
            </p>
            <p>
              In terms of CX, we work with insurance companies to design and
              implement customer experience strategies that are tailored to
              their specific needs and goals.
            </p>
            <p>
              Whether its enabling IoT for banking services, building the next
              generation of banking applications or streamlining and
              modernization of core banking operations, we have the experience,
              the people, the processes and the methodologies to help you get
              there.
            </p>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceService;
