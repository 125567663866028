import React from 'react';
import "./Peoplevalue.css";

const Peoplevalue = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='value_content'>
            <h1>Our Values</h1>
            <p>We are not just a leading digital transformation company; we also pride ourselves on having an exceptional work culture. Here are the core values that drive our culture:</p>
          </div>
        </div>

        {/* Values section */}
        <div className='col-lg-12'>
          <div className='row d-flex flex-row justify-content-center'>
            <div className='col-12 col-sm-6 col-md-4 col-lg-4 BOX_01'>
              <h1 className="box_number">01</h1>
              <p className="box_text">Respect</p>
            </div>
            <div className='col-12 col-sm-6 col-md-4 col-lg-4 BOX_02'>
              <h1 className="box_number">02</h1>
              <p className="box_text">Integrity</p>
            </div>
            <div className='col-12 col-sm-6 col-md-4 col-lg-4 BOX_03'>
              <h1 className="box_number">03</h1>
              <p className="box_text">Trust</p>
            </div>
          </div>
        </div>

        <div className='col-lg-12'>
          <div className='row d-flex flex-row justify-content-center '>
            <div className='col-12 col-sm-6 col-md-4 col-lg-4 BOX_04'>
              <h1 className="box_number">04</h1>
              <p className="box_text">Equality</p>
            </div>
            <div className='col-12 col-sm-6 col-md-4 col-lg-4 BOX_01'>
              <h1 className="box_number">05</h1>
              <p className="box_text">Customer</p>
            </div>
            <div className='col-12 col-sm-6 col-md-4 col-lg-4 BOX_02'>
              <h1 className="box_number">06</h1>
              <p className="box_text">Innovation</p>
            </div>
          </div>
        </div>
      </div>

      {/* Perks & Benefits section */}
      <div className='row'>

         <div className='col-lg-12'>
          <div className='value_content'>
            <h1>Perks & Benefits</h1>
            <p>At Agilisium, we prioritize the physical, financial, and emotional well-being of our employees. Here’s how we drive our workforce to deliver their best.</p>
          </div>
        </div>

        <div className='col-lg-12'>
          <div className='row d-flex flex-row justify-content-center'>
            <div className='col-12 col-sm-6 col-md-3 col-lg-3 Benefits_1'>
              <i className="bi bi-amd"></i>
              <h6>Healthcare Insurance & Wellness Programs</h6>
            </div>
            <div className='col-12 col-sm-6 col-md-3 col-lg-3 Benefits_2'>
              <i className="bi bi-android2"></i>
              <h6>Vacation / Paid Time Off</h6>
            </div>
            <div className='col-12 col-sm-6 col-md-3 col-lg-3 Benefits_3'>
              <i className="bi bi-backpack2-fill"></i>
              <h6>Performance Bonus & Rewards Programs</h6>
            </div>
            <div className='col-12 col-sm-6 col-md-3 col-lg-3 Benefits_4'>
              <i className="bi bi-bar-chart-fill"></i>
              <h6>Career Growth Programs</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Peoplevalue;
