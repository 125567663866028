import React from 'react'
import "./DataBlogs.css"
import img3 from "../../Webapplication/Blogs/Assets/1.jpg";
import img1 from "../../Webapplication/Blogs/Assets/2.jpg";
import img2 from "../../Webapplication/Blogs/Assets/3.jpg";

const DataBlogs = () => {
  return (
    
    <div className='container-fluid DAcontainer_blog' id="Blogs">
        <div className='row'>
        <div className='col-lg-12 DABlog_Studies '>
          <h1>LEADING OUR WAY</h1>
          <p>BLOGS</p>
        </div>
        </div>
        <div className='row'>
            <div className='col-lg-4'>
                <div className='DABlog_1'>
               <div className='DAzoom-img'>
                <img src={img1} alt=""  className='img-fluid' />
                </div>
                <h4>NEW WAYS OF WORKING – DRIVE BUSINESS AGILITY</h4>
                <div className='DABlog_1_sub'>
                <span class="badge text-bg-warning">AMD</span>
                <p>
                FEBRUARY 13, 2023</p>
                </div>
                </div>
            </div>
            <div className='col-lg-4'>
                <div className='DABlog_1'>
               <div className='DAzoom-img'>
                <img src={img2} alt="" className='img-fluid' />
                </div>
                <h4>MOBILE BANKING – CURRENT AND FUTURE STATE</h4>
                <div className='DABlog_1_sub'>
                <span class="badge text-bg-warning">AMD</span>
                <p>
                JANUARY 27, 2023</p>
                </div>
                </div>
            </div>
            <div className='col-lg-4'>
                <div className='DABlog_1'>
               <div className='DAzoom-img'>
                <img src={img3} alt="" className='img-fluid' />
                </div>
                <h4>A GUIDE TO EDGE COMPUTING</h4>
                <div className='DABlog_1_sub'>
                <span class="badge text-bg-warning">FUTURE TECH</span>
                <p>
                MARCH 8, 2023</p>
                </div>
                </div>
            </div>

        </div>
    </div>
    
  )
}

export default DataBlogs
