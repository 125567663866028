import React from "react";
import "./HTCaseStudies.css";
import img1 from "../HTCaseStudies/Assets/HitechCase2.jpg"
import img2 from "../HTCaseStudies/Assets/HitechCase3.jpg"
const HTCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudy">
      
      <div className="row">
        <div className="col-12 heading text-center">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        </div>

        <div className='row d-flex flex-row'>

        <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img1}
              alt="img1"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_1 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                BUILDING CORE APPLICATION FOR LEASING AND RELATED FINANCIAL
                SERVICES
              </h5>
              <p>
              finance lease for small and medium-sized companies. The scope of engagement includes assessment of IT systems landscape, to-be architecture, grand design and phased construction.
              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img2}
              alt="img2"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_2 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>CREDIT MANGER APPLICATION DEVELOPMENT AND MAINTENANCE</h5>
              <p>
              credit risk management solutions and services which are widely used by the country’s banking and financial institutions, insurance and telecommunication companies, large corporations, SMEs, legal firms and statutory bodies.
              </p>
            </div>
          </div>
          </div>

        </div>
        </div>
  );
};

export default HTCaseStudies;
