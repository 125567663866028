import React from "react";
// import backgroundImage from './Assets/Over1.jpg';
import backgroundImage from './Assets/Over1.jpg'
import './HTOverview.css'

const HTOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="htbackground">
            <h1>HI- TECH</h1>

            <p>Deliver innovation at the speed of Thought.</p>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12 d-flex flex-wrap justify-content-center">
          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Services")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Services
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Case Studies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Connect")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Connect
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
          </div>
        </div>
      </div>
      <div className="text-info text-center">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-12 my-5 Overview_content">
          <p>
            LDAPP’s helps other organizations in the hi-tech industry to
            modernize and transform their productsand operations through the use
            of digital technologies.
          </p>
          <p>
            In order to succeed in today’s competitive landscape, it is
            important for businesses to be able to quickly and effectively
            respond to changing market conditions and customer needs, and to do
            so in a way that is both efficient and scalable. By leveraging the
            right digital technologies and business models, companies can
            achieve significant improvements in their operations, customer
            satisfaction, and financial performance.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HTOverview;
