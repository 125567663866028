import React from 'react'
import "./AppCapabilities.css"
import img1 from "../../Mobile/MobileCapabilities/Assets/Mb3.jpg";
import img2 from "../../Mobile/MobileCapabilities/Assets/Mb4.jpg";
import img3 from "../../Mobile/MobileCapabilities/Assets/mb2.jpg";

const AppCapabilities = () => {
  return (
    <div>
        <div className="container-fluid "id="Capabilities" >
    <div className="row">
      <div className="col-lg-12">
        <div>
          <h1 className='APPApp1'>APP DEV & MAINTENANCE CAPABILITIES
          </h1>
        </div>
      </div>
      <div className="col-lg-12 APPService_Content_01 my-5 ">
        <div className="col-lg-7 mx-3">
          <div className="APPServices_content01">
            <h3>MOBILE APPLICATION DEVELOPMENT

            </h3>
            <p>
            In a mobile-first world, we offer specialized services in developing and maintaining mobile applications across various platforms. Our expertise ensures your mobile apps deliver a seamless user experience and high performance.            </p>
             <hr />
            <ul className="APPicon_li">
              <li className="APPlist-group-item">
                <i className="bi bi-emoji-heart-eyes"></i> Well-versed with Modern Mobile Tech Stacks
              </li>
              <li className="APPlist-group-item">
                <i className="bi bi-emoji-laughing"></i> Exploring the power of AI to build mobile apps.

              </li>
              <li className="APPlist-group-item">
                <i className="bi bi-emoji-smile"></i> End-to-end Support from Ideation to Post-launch
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 APPImage_service01">
          <img src={img1} alt=""  className="img-fluid"/>
        </div>
      </div>
      <div className="col-lg-12 APPService_Content_02 ">
        <div className="col-lg-5 APPImage_service02">
          <img src={img2} alt="" />
        </div>
        <div className="col-lg-7  ">
          <div className="APPServices_content02 ">
            <h3>CUSTOM APPLICATION DEVELOPMENT & MAINTENANACE</h3>
            <p>
            The LDAPP SOLUTIONS mobile app development team are core contributors to popular frameworks like React Native and Flutter. We understand the nuances of building a mobile app better than most.
            Collaborate with us today to develop a mobile app that is engaging and impactful.
            </p>
            <hr />
            <ul className="APPicon_li">
              <li className="APPlist-group-item">
                <i className="bi bi-emoji-heart-eyes"></i>Cross-platform Development

              </li>
              <li className="APPlist-group-item">
                <i className="bi bi-emoji-laughing"></i>Mobile App Design
</li>
              <li className="APPlist-group-item">
                <i className="bi bi-emoji-smile"></i> App Maintenance and Support</li>
              <li className="APPlist-group-item">
                <i className="bi bi-emoji-heart-eyes"></i> App Optimization
                <li className="APPlist-group-item"></li>
              <i className="bi bi-emoji-heart-eyes"></i>Quality Assurance

              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-12 APPService_Content_01 my-5">
        <div className="col-lg-7 mx-3">
          <div className="APPServices_content01">
            <h3>Ongoing Maintenance & Support

            </h3>
            <p>
              
            We provide comprehensive maintenance services to keep your applications running smoothly. Our support includes regular updates, performance monitoring, bug fixes, and enhancements to ensure your applications remain up-to-date and secure.
            </p>
            <hr />
            <ul className="APPicon_li ">
              <li className="APPlist-group-item">
                <i className="bi bi-emoji-heart-eyes"></i>
                Quality Assurance and Software Testing Services
              </li>
              <li className="APPlist-group-item">
                <i className="bi bi-emoji-laughing"></i> Legacy systems to
                UI/UX Design And Development Services
              </li>
              <li className="APPlist-group-item">
                <i className="bi bi-emoji-smile"></i>Business Analysis Services
                
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 APPImage_service01">
          <img src={img3} alt=""  className="img-fluid" />
        </div>
      </div>
     
    </div>
  </div>
      
    </div>
  )
}

export default AppCapabilities
