import React from "react";
import "./RetailCaseStudies.css";
import img1 from "../RetailCaseStudies/Assets/Retail2.jpg";

const RetailCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudy">
      
      <div className="row">
        <div className="col-12 heading text-center ">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        </div>

        <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="alll">
            <img
              src={img1}
              alt="img1"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_1">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>PROCESS AUTOMATION USING UI PATH FOR AN AUTOMOTIVE GIANT</h5>
              <p>
              European Multinational automotive corporation desires to automate processes using UI Path RPA technology in a managed services model to ensure end to end ownership and uninterrupted quality services.
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};

export default RetailCaseStudies;
