import React from 'react';
import './Peopleall.css';
import img1 from "../../PeopleCareers/Peopleall/Assets/1.jpeg";
import img2 from "../../PeopleCareers/Peopleall/Assets/2.jpeg";
import img3 from "../../PeopleCareers/Peopleall/Assets/3.jpeg";
import img4 from "../../PeopleCareers/Peopleall/Assets/4.jpeg";
import img5 from "../../PeopleCareers/Peopleall/Assets/5.jpeg";

const Peopleall = () => {
    return (
        <div className='container'>
            <div className='row'>

                <div className='col-12'>
                    <div className='people_title text-center'>
                        <h1>Life at LDAPP</h1>
                        <p>
                            At LDAPP, we have created a positive work environment that supports your excellence,
                            stimulates your curiosity, and fuels your creativity. No matter where you are, you can be part of our cohesive workforce!
                        </p>
                    </div>
                </div>

                <div className='col-12 py-4'>
                    <div className='row d-flex flex-row'>
                        <div className='col-lg-3 col-md-4 col-sm-12 Box_1 text-center'>
                            <h1>Ownership</h1>
                        </div>
                        <div className='col-lg-6 col-md-4 col-sm-12 Box_2 text-center'>
                            <img src={img1} alt="Ownership" className="img-fluid" />
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-12 Box_3 text-center'>
                            <h1>Teamwork</h1>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12 d-flex flex-wrap'>

                            <div className='col-lg-3 col-md-4 col-sm-12 image_2 text-center'>
                                <img src={img3} alt="Self-Reliance" className="img-fluid" />
                            </div>

                            <div className='col-lg-9 col-md-8 col-sm-12'>
                                <div className='row d-flex flex-row'>
                                    <div className='col-lg-5 col-md-5 col-sm-6 Box_4 text-center'>
                                        <h1>Self-Reliance</h1>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-6 Box_5 text-center'>
                                        <h1>Passion</h1>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-12 image_3 text-center'>
                                        <img src={img2} alt="Passion" className="img-fluid" />
                                    </div>
                                </div>
                                <div className='row d-flex flex-row mt-3'>
                                    <div className='col-lg-4 col-md-4 col-sm-6 Box_6 text-center'>
                                        <h1>Empathy</h1>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-12 image_4 text-center'>
                                        <img src={img4} alt="Empathy" className="img-fluid" />
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-sm-6 Box_7 text-center'>
                                        <h1>Dedication</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Peopleall;
