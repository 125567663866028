import React from "react";
import Header from "../../Components/Header/Header";
import DevopsOverview from "../../Components/Services/DevopsAgile/DevopsOverview/DevopsOverview";
import DevopsCapabilities from "../../Components/Services/DevopsAgile/DevopsCapabilities/DevopsCapabilities";
import DevopsTools from "../../Components/Services/DevopsAgile/DevopsTools/DevopsTools";
import DevopsStudies from "../../Components/Services/DevopsAgile/DevopsStudies/DevopsStudies";
import DevopsBlogs from "../../Components/Services/DevopsAgile/DevopsBlogs/DevopsBlogs";
import Footer from '../../Components/Footer/Footer'

const Devops = () => {
  return (
    <>
      <Header />
      <DevopsOverview />
      <DevopsCapabilities />
      <DevopsTools />
      <DevopsStudies />
      <DevopsBlogs/>
     <Footer/>
    </>
  );
};

export default Devops;
