import React from "react";
import "./DigitalCustomerCapabilities.css";
import img1 from "./Assets/img1.jpg";
import img2 from "./Assets/img2.jpg";
import img3 from "./Assets/img3.jpg";
import img4 from "./Assets/DCx2.jpg";

const DigitalCustomerCapabilities = () => {
  return (
    <div className="container-fluid " id="Capabilities">
      <div className="row">
        <div className="col-lg-12">
          <div>
            <h1>DIGITAL CUSTOMER EXPERIENCE CAPABILITIES</h1>
          </div>
        </div>
        <div className="col-lg-12 DGService_Content_01 my-5 ">
          <div className="col-lg-7 mx-3">
            <div className="DGServices_content01">
              <h3>FRONT END UX UI:</h3>
              <p>
                UX design focuses on creating a seamless and intuitive
                experience for the user, taking into account factors such as
                usability, accessibility, and overall user satisfaction. It
                includes research, wireframing, prototyping, and testing to
                ensure that the end product is user-centered.
                <br />
                UI design focuses on the visual elements of the product, such as
                layout, color scheme, typography, and iconography, to create an
                aesthetically pleasing and consistent design.
              </p>
              <hr />
              <ul className="DGicon_li">
                <p>
                  Together, front-end UX and UI aim to create a cohesive and
                  engaging digital experience for the user that is easy to use
                  and visually appealing.
                </p>
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i> Mobile – Hybrid
                  Containers & SDKS Implementation, Native Applications &
                  platform centric Development, Validation
                </li>
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-laughing"></i>UX – Experience
                  Research & Analysis, Prototyping & Design,Evaluation &
                  Assessment
                </li>
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-smile"></i> WebUI – RWD
                  Implementation, Framework Migration, Platform Centric UI
                  realization (SFDC,AEM, Hybris) (CoE) Setup
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 DGImage_service01">
            <img src={img1} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-12 DGService_Content_02 ">
          <div className="col-lg-5 DGImage_service02">
            <img src={img2} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="DGServices_content02 ">
              <h3>CONTENT MANAGEMENT & MARKETING</h3>
              <p>
                LDAPP SOLUTIONS uses Content management systems (CMS) that provide tools
                for managing digital content and making it available to website
                visitors. Creating, organizing, and distributing digital content
                such as text, images, videos, and audio through various channels
                for marketing. This can include content creation, curation,
                editing, and publishing, as well as content governance,
                workflow, and archiving.
              </p>
              <hr />
              <ul className="DGicon_li">
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>Transformation
                  Services
                </li>
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-laughing"></i>Application
                  Development & Maintenance
                </li>
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-smile"></i> Experience Solutions,
                  DevOps Framework
                </li>
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>CMS Integration
                  Accelerators
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-12 DGService_Content_01 my-5">
          <div className="col-lg-7 mx-3">
            <div className="DGServices_content01">
              <h3>CUSTOMER MANAGEMENT</h3>
              <p>
                Customer management refers to the processes and practices a
                company uses to acquire, retain, and grow its customer base. It
                encompasses a variety of activities such as customer
                acquisition, customer service, customer retention, and customer
                relationship management (CRM).
                <br />
                Customer management is a crucial aspect of any business as it
                focuses on understanding, engaging, and providing value to
                customers to build long-term relationships and increase customer
                loyalty.
              </p>
              <hr />
              <ul className="DGicon_li">
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>
                  Transformation Services – Customer 360, Engagement Center,
                  Loyalty Management, Omni Channel Sales & Service, Integrated
                  Campaign Management
                </li>
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-laughing"></i> Engagement Models –
                  Cloud Enablement for CRM, CRM Operations Support, Upgrades &
                  Migration, CRM Testing & Automation, CRM CoE/Shared Services
                  Set-Up
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 DGImage_service01">
            <img src={img3} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-12 DGService_Content_02 ">
          <div className="col-lg-5 DGImage_service02">
            <img src={img4} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="DGServices_content02 ">
              <h3>DIGITAL TRANSFORMATION</h3>
              <p>
                Technology solutions for the customer journey aid businesses in
                better comprehending and interacting with their clients,
                increasing client satisfaction, client loyalty, and client
                revenue. The objective is to continuously measure and improve
                the customer journey in order to offer a smooth, tailored, and
                optimum customer experience.
              </p>
              <hr />
              <ul className="DGicon_li">
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>Journey mapping:
                  This involves creating visual representations of the customer
                  journey to understand the various touchpoints and interactions
                  that customers have with a company.
                </li>
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-laughing"></i>Customer analytics:
                  This involves collecting and analyzing data on customer
                  behavior, preferences, and feedback to gain insights into
                  customer needs and pain points.
                </li>
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-smile"></i> Personalization: This
                  involves using data and analytics to tailor the customer
                  experience to individual customers, providing a more
                  personalized and relevant experience.
                </li>
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-smile"></i>Automation: This involves
                  using technology to automate repetitive or time-consuming
                  tasks, such as customer service interactions or marketing
                  campaigns.
                </li>
                <li className="DGlist-group-item">
                  <i className="bi bi-emoji-smile"></i> Optimization: This
                  involves using data and analytics to measure the effectiveness
                  of customer journey initiatives and make adjustments to
                  improve the overall customer experience.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalCustomerCapabilities;
