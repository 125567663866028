import React from "react";
import "./BusinessCapabilities.css";
import img1 from "./Assets/img1.jpg";
import img2 from "./Assets/img2.jpg";
import img3 from "./Assets/img3.jpg";
import img4 from "./Assets/img4.jpg";

const BusinessCapabilities = () => {
  return (
    <div className="container-fluid " id="Capabilities">
      <div className="row">
        <div className="col-lg-12">
          <div>
            <h1>BUSINESS PROCESS CAPABILITIES</h1>
          </div>
        </div>
        <div className="col-lg-12 BMService_Content_01 my-5 ">
          <div className="col-lg-7 mx-3">
            <div className="BMServices_content01">
              <h3>SHARED SERVICES</h3>
              <p>
                SHARED SERVICES Optimum works with some of the leading
                organizations to deliver a comprehensive suite of Shared
                Services including:
              </p>
              <hr />
              <ul className="BMicon_li">
                <li className="BMlist-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>Multi-Lingual
                  Support – We support clients across various geographies and
                  cultures and offer local language support in multiple
                  languages including Mandarin, Japanese, Korean, Bahasa
                  Indonesia, Bahasa Malaysia, Thai, and Tagalog.
                </li>
                <li className="BMlist-group-item">
                  <i className="bi bi-emoji-laughing"></i>Quants – 
                  With top talent for all applications in financial management.
                  Whether the need is for “sell-side” expertise concerned with
                  derivatives pricing and risk management, or buy-side”
                  expertise, we work with you to get you the right services.
                </li>
                <li className="BMlist-group-item">
                  <i className="bi bi-emoji-smile"></i>Banking Operations –
                  LDAPP’s expertise spans the full spectrum of banking
                  operations from Core Banking operation to Front Office
                  Support, Client on-boarding, KYC Initiatives, Clearing
                  Systems, Reconciliations, and Settlements. Over the past 25
                  years, we have worked with leading banks to streamline,
                  transform and manage their banking operations.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 BMImage_service01">
            <img src={img1} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-12 BMService_Content_02 ">
          <div className="col-lg-5 BMImage_service02">
            <img src={img2} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="BMServices_content02 ">
              <h3>ROBOTIC PROCESS AUTOMATION</h3>
              <p>
                LDAPP SOLUTION end-to-end RPA services combined with its Intelligent
                RPA approach comprising of scientific process assessment for
                analyzing fragmented & complex processes, modular & reusable
                architecture for rapid delivery & deployment and extreme
                automation using cognitive bots, help enterprises design their
                Digital workforce strategy & roadmap and drive implementation,
                production & management of bots, through an inhouse talent that
                is certified across all major RPA platforms and AI/ML tools.
              </p>
              <hr />
            </div>
          </div>
        </div>
        <div className="col-lg-12 BMService_Content_01 my-5">
          <div className="col-lg-7 mx-3">
            <div className="BMServices_content01">
              <h3>SAP</h3>
              <p>
                We help build intelligent enterprises by implementing SAP’s
                industry specific solutions. Our SAP advisors and tool agnostic
                domain experts specialize in providing process expertise with a
                user centric approach to better understand critical business
                processes across different functions like finance, procurement,
                product, sales, quality, supply chain, insights etc..
                <br />
                With extensive experience in configuring SAP’s industry
                solutions as per industry best practices, we successfully
                deliver business benefits such as step change in productivity,
                agility and efficiency.
              </p>
              <hr />
              <ul className="BMicon_li">
                <li className="BMlist-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>
                  Enterprise Transformation with SAP HANA – We help enterprises
                  with smooth and successful transformation to SAP (S/4) HANA.
                  With a deep expertise in the right EA tools, strategies and
                  process reengineering & change management processes,
                </li>
                <li className="BMlist-group-item">
                  <i className="bi bi-emoji-laughing"></i>we build new systems
                  through migration and also support optimization and
                  enhancement of existing systems for gradual transition.Through
                  our vast experience in end-to-end implementation of SAP HANA,
                  we bring in agility and speed in our delivery, thus enabling
                  enterprises to gain more value from faster enterprise
                  transformation.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 BMImage_service01">
            <img src={img3} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="col-lg-12 BMService_Content_02 ">
          <div className="col-lg-5 BMImage_service02">
            <img src={img4} alt="" />
          </div>
          <div className="col-lg-7  ">
            <div className="BMServices_content02 ">
              <h3>SALESFORCE</h3>
              <p>
                We help enterprises transform customer experience across
                marketing, sales and service verticals by using Salesforce with
                a combination of our business process expertise and deep data &
                integration capabilities.
                <br />
                We plan, design and deliver end-to-end Salesforce solutions
                including data & integration strategies, custom configurations,
                training & enablement and managed services, thus enabling
                enterprises to anticipate and respond to rising customer
                expectations and build loyalty.
              </p>
              <hr />
              <ul className="BMicon_li">
                <li className="BMlist-group-item">
                  <i className="bi bi-emoji-heart-eyes"></i>Force Cloud
                  Transformation – Transition from legacy systems to latest
                  services from Force.com such as Sales cloud, Service cloud,
                  Community cloud, Marketing cloud and Health cloud, by
                  leveraging our expertise in business process reengineering and
                  experience in handling complex data migration challenges using
                  agile methodologies to deliver more value.
                </li>
                <li className="BMlist-group-item">
                  <i className="bi bi-emoji-laughing"></i> Sales and Service
                  Implementation – Drive end-to-end Sales and Service Cloud
                  implementation in an agile delivery model with expertise in
                  configuration of services with both classic and Lightening
                  features and customization of workflows, reports and
                  dashboards as per business specific needs. With a team that
                  has strong experience across tools and technologies like
                  Visualforce, Apex, Lightening Web Components and Javascript,
                  we automate and streamline business processes to positively
                  impact ROI.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessCapabilities;
