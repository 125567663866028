import React from "react";
import "./GlobalOverview.css";

const GlobalOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="Content_Development12">
            <h1>GLOBAL CAPABILITY CENTERS</h1>

            <p>
              Unlocking Business Potential with GCC Services in IT:
              <br />
              Empowering Growth, Security, and Collaboration
            </p>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12 d-flex flex-wrap justify-content-center">
          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Services")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Services
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Case Studies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Connect")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Connect
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
          </div>
        </div>
      </div>
      <div className="text-info text-center">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-12 my-5 Overview_content">
          <p>
            Creation of a GCC (Global Capability Center) is a compelling
            business case for any IT Organization. Indian subcontinent has been
            the epicentre of this transformation due to
          </p>
          <p>
            Talent Availability – India’s talent availability is a vast
            reservoir of untapped potential, ready to fuel the world with its
            diverse skills and unwavering determination
          </p>
          <p>
            IT Ecosystem – India’s IT ecosystem is a powerhouse, where
            technological innovation converges with exceptional talent,
            propelling India to the forefront of the digital revolution
          </p>
          <p>
            Cost Optimization – Unlock a competitive advantage, enabling
            businesses to thrive by leveraging the optimal balance between
            efficiency and affordability
          </p>
          <p>
            Innovation – Unparalleled with dedicated R&D centers fostering
            innovation in collaboration with local research institutions,
            universities, and start-up ecosystems
          </p>
          <p>
            Risk Mitigation – Diversifying operations across different
            geographical locations reduces dependency on single location and
            ensures Business Continuity and provide Disaster Recovery / Backup
            support enhancing business Resilience
          </p>
        </div>
      </div>
    </div>
  );
};

export default GlobalOverview;
