import React from "react";
import img01 from "../../Components/Recentwork/Assests/1.jpg";
import img02 from "../../Components/Recentwork/Assests/2.jpg";
import img03 from "../../Components/Recentwork/Assests/3.jpg";
import "./Recentwork.css";

const Recentwork = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-center align-items-center mb-4">
          <h1>Our Most Recent Work</h1>
        </div>
      </div>

      <div className="row d-flex flex-row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center mb-4">
          <div className="overall_1">
            <img
              src={img01}
              alt=""
              className="image1"
              style={{ width: "auto", height: "500px" }}
            />
            <div className="overlay">
              <div className="icon_1 mb-1">
                <i className="bi bi-file-pdf-fill"></i> E BOOK
              </div>
              <h5 className="mb-4">
                E-commerce
              </h5>
              <p className="mt-2">
              Successfully launched a robust e-commerce platform, enhancing user experience
               and driving notable sales growth with advanced features.
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-6 col-lg-4 d-flex justify-content-center mb-4">
          <div className="overall_1">
            <img
              src={img02}
              alt=""
              className="image1"
              style={{ width: "auto", height: "500px" }}
            />
            <div className="overlay ">
              <div className="icon_2 mb-2">
                <i className="bi bi-file-text-fill"></i> CASE STUDY
              </div>
              <h5>
              </h5>
              <p>
              <p >
              Implemented a personalized recommendation system, 
              improving customer engagement and streamlining the shopping experience across devices.
              </p>
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center mb-4">
          <div className="overall_1">
            <img
              src={img03}
              alt=""
              className="image1"
              style={{ width: "auto", height: "500px" }}
            />
            <div className="overlay">
              <div className="icon_3 mb-2">
                <i className="bi bi-file-earmark-break-fill"></i> BLOG
              </div>
              <h5>
              Why we do it better?
              </h5>
              <p>
              We excel in app development by delivering innovative, scalable solutions tailored to your unique business needs with exceptional speed and quality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recentwork;
