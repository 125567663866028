import React from 'react'
import "./DataCapabilities.css"
import img1 from "../../DataAnalyst/DataCapabilities/Assets/Data01.jpg";
import img2 from "../../DataAnalyst/DataCapabilities/Assets/DataA1.jpg";
import img3 from "../../DataAnalyst/DataCapabilities/Assets/DataA3.jpg";


const DataCapabilities = () => {
  return (
    <div className="container-fluid "id="Capabilities" >
    <div className="row">
      <div className="col-lg-12">
        <div>
          <h1>DATA AND ANALYTICS CAPABILITIES</h1>
        </div>
      </div>
      <div className="col-lg-12 DAService_Content_01 my-5 ">
        <div className="col-lg-7 mx-3">
          <div className="DAServices_content01">
            <h3>DATA INTELLIGENCE ADVISORY SERVICES</h3>
            <p>
            Enterprise Data Maturity is key for any organization to be competitive in the data-driven world, from Big Data Lake analytics to Machine Learning (ML) and Artificial Intelligence (AI). it is imperative to build, integrate, and modernize your data analytics applications, tool and platforms.
            LDAPP Consulting Offers consulting and strategic advisory services that evaluates your dataanalytics landscape help enable your unique organizational goals. identifying the best solution with actionable roadmap to meet and empower better decisions.
            </p>
            <hr />
            <ul className="DAicon_li">
              <li className="DAlist-group-item py-3">Optimum Data Intelligence ADVISORYservices: <br />


                <i className="bi bi-emoji-heart-eyes"></i>Modern Data Architecture Roadmap

              </li>
              <li className="DAlist-group-item">
                <i className="bi bi-emoji-laughing"></i> Enterprise DevOps and Data Maturity Roadmap

              </li>
              <li className="DAlist-group-item">
                <i className="bi bi-emoji-smile"></i>Big Data Analytics and Graph database Roadmap
</li>
          
              <li className="DAlist-group-item">
                <i className="bi bi-emoji-smile"></i>Business Intelligence and Predictive Modelling


              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 DAImage_service01">
          <img src={img1} alt=""  className="img-fluid"/>
        </div>
      </div>
      <div className="col-lg-12 DAService_Content_02 ">
        <div className="col-lg-5 DAImage_service02">
          <img src={img2} alt="" />
        </div>
        <div className="col-lg-7  ">
          <div className="DAServices_content02 ">
            <h3>ADVANCED ANALYTICS
            </h3>
            <p>
            Are you able to use your ever-growing data to gain essential insights? LDAPP Consulting’s data professionals bring a unique combination of experience and talent to build Advanced Analytics platforms. The LDAPP Consulting team will evaluate your applications, data landscape to identify the best solution to meet your unique goals.
</p>
            <hr />
            <ul className="DAicon_li">
              <li className="DAlist-group-item py-3"> OurAdvanced Analytics capabilities: <br />


                <i className="bi bi-emoji-heart-eyes"></i>Modern Data Analytics platform

              </li>
              <li className="DAlist-group-item">
                <i className="bi bi-emoji-laughing"></i> Build Big Data Lakes/ Real time Analytics

              </li>
              <li className="DAlist-group-item">
                <i className="bi bi-emoji-smile"></i> Enterprise Data Warehouses / Graph Database

              </li>
              <li className="DAlist-group-item">
                <i className="bi bi-emoji-heart-eyes"></i> Machine Learning Model Development

              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-12 DAService_Content_01 my-5">
        <div className="col-lg-7 mx-3">
          <div className="DAServices_content01">
            <h3>DATA ENGINEERING
            </h3>
            <p>
            Data Engineering helps organisations modernise Data landscape to deliver you the best in class solutions enables enterprises to achieve real-timedecision support systems. OptimumConsulting’s veteran data professionals execute data ingestions to visualizations quickly and seamlessly, with security always top-of-mind.
            </p>
            <hr />
            <ul className="DAicon_li">
              <li className="DAlist-group-item py-4"> Data Engineering services include: <br />
   <i className="bi bi-emoji-heart-eyes"></i>
                Modernize Data Pipelines / Workflows
                </li>
              <li className="DAlist-group-item ">
<i className="bi bi-emoji-laughing"></i> Data systems security

              </li>
              <li className="DAlist-group-item">
                <i className="bi bi-emoji-smile"></i>Modernize Data warehouse
</li>
<li className="DAlist-group-item">
                <i className="bi bi-emoji-smile"></i>Platform/Tools Upgrade

</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 DAImage_service01">
          <img src={img3} alt=""  className="img-fluid" />
        </div>
      </div>
     
    </div>
  </div>
  )
}

export default DataCapabilities
