import React from 'react';
import "./BankOverview.css";

const BankOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="Content_Developmentb">
            <h1>BANKING & FINANCIAL SERVICES</h1>
            <p>
              IT Services For The Next Generation Banking Platforms
            </p>
          </div>
        </div>
      </div>

      <div className="row my-4">
        <div className="col-12 d-flex flex-wrap justify-content-center">
          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
          </div>

          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Services")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Services
            </button>
          </div>

          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Case Studies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
          </div>

          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Connect")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Connect
            </button>
          </div>

          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
          </div>
        </div>
      </div>

      <div className="text-info text-center">
        <hr />
      </div>

      <div className="row my-3" id="Overview">
        <div className="col-12 my-5 Overview_content">
          <p>
            Banking is moving beyond banks. As banking services become embedded in other sectors,
            firms are pushing their traditional boundaries to build cross-industry ecosystems and create
            value at every stage of the customer journey. This boundaryless model, supported by the right
            intelligence, helps create innovative offerings and experiences.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BankOverview;
