import React from 'react'
import './DataCaseStudies.css'
import img1 from "../../DataAnalyst/DataCaseStudies/Assets/DataCase1.webp";
import img2 from "../../DataAnalyst/DataCaseStudies/Assets/DataCase2.jpg";
import img3 from "../../DataAnalyst/DataCaseStudies/Assets/DataCase3.jpg";

const DataCaseStudies = () => {
  return (
    <div className='container-fluid container_CaseStudy'id="CaseStudies">

    <div className='row'>
      <div className='col-12 heading text-center'>
        <h1>LEADING OUR WAY</h1>
        <p>CASE STUDIES</p>
      </div>
      </div>

      <div className='row'>

       <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
        <div className="alll">
          <img
            src={img1}
            alt="img1"
            className="img1"
          />
          <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
            <div className="icon_1 mb-2">
              <i className="bi bi-file-pdf-fill"></i>E BOOK</div>
            <h5>
            DEVOPS IMPLEMENTATION FOR A GLOBAL  BANK

            </h5>
            <p>
            LDAPP SOLUTIONS has partnered with clients in digital and DevOps initiatives to improve overall delivery efficiency and TAT through CI/CD automation.
            </p>
          </div>
        </div>
        </div>

        <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
        <div className="alll">
          <img
            src={img2}
            alt="img2"
            className="img1"
          />
          <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
            <div className="icon_2 mb-2">
              <i className="bi bi-file-pdf-fill"></i>E BOOK</div>
            <h5>
            CREDIT MANGER APPLICATION DEVELOPMENT AND MAINTENANCE

            </h5>
            <p>
            The facilitates credit extensions and delivers a complete portfolio of credit risk management solutions and services which are widely used by the country’s banking and financial institutions,  large corporations, SMEs, legal firms and statutory bodies.
            </p>
          </div>
        </div>
        </div>

        <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
        <div className="alll">
          <img
            src={img3}
            alt="img3"
            className="img1"
          />
          <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
            <div className="icon_3 mb-2">
              <i className="bi bi-file-pdf-fill"></i>E BOOK</div>
            <h5>
            TRADE FINANCE TRANSFORMATION

            </h5>
            <p>
            Legacy platforms with single application for deployment created huge resistance to new developments in case of organizational changes and/or business reengineering
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>
  )
}

export default DataCaseStudies
