import React from 'react';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Aboutcompany from '../../Components/Aboutus/Aboutcompany';
import Fastfact from '../../Components/Fastfact/Fastfact';

const AboutUs = () => {
  return (
    <div>
        <Header/>
        <Aboutcompany/>
        <Fastfact/>
        <Footer/>    
    </div>
  )
}

export default AboutUs
