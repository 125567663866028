import React from "react";
import "./TeleComCaseStudies.css";
import img1 from "../TeleComCaseStudies/Assets/Telecom1.jpg";

const TeleComCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudy" id="Case Studies">

       <div className="row">
        <div className="col-12 heading text-center">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        </div>


       <div className='row d-flex justify-content-center'>
          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img1}
              alt="img"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_1 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>The Role of Telecoms in Facilitating Remote Work</h5>

              <p>
              Our team of networking experts have helped a diverse set of companies bring their organisations into the 21st century with enterprise-grade internet speeds and secure future-proof networks.
              </p>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default TeleComCaseStudies;
