import React from 'react';
import "./JoinOurTeam.css";
import img1 from "../../Components/JoinOurTeam/Assets/1.jpg";
import img2 from "../../Components/JoinOurTeam/Assets/2.jpg";
import img3 from "../../Components/JoinOurTeam/Assets/3.jpg";

const JoinOurTeam = () => {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-12 JoinOurTeam_row1 text-center d-flex align-items-center justify-content-center'>
                    <div className='JoinOurTeam_content'>
                        <h1 className="display-4">Come Join Our Team</h1>
                        <h5 className="my-4">
                            We're seeking driven individuals with a passion for problem-solving, client-centricity, continuous learning, and exceptional communication skills. 
                            You would collaborate closely with founders and industry leaders and solve impactful problems.
                        </h5>
                        <a href="/Candicateprofile" className='JoinOurTeam_Button btn btn-primary'>Get Started <i className="bi bi-arrow-up-right"></i></a>
                    </div>
                </div>

                <div className='row my-5 JoinOurTeam_row2'>
                    <div className='col-lg-5 col-md-6 col-sm-12'>
                        <img src={img1} alt="" className='JoinOurTeam_image img-fluid mb-4 mb-md-0'/>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 JoinOurTeam_content1 d-flex flex-column justify-content-center'>
                        <h2 className="h2-responsive">Straatix Internship Programme</h2>
                        <p className="my-4 text-justify">
                            Embark on a transformative internship journey with our firm. Gain hands-on experience, work alongside experts, and make a real impact in 3-6 months. 
                            Join us to elevate your skills and launch your career.
                        </p>
                        <a href="/Candicateprofile" className='JoinOurTeam_Button btn btn-primary'>Apply Now <i className="bi bi-arrow-up-right"></i></a>
                    </div>
                </div>

                <div className='row my-5 JoinOurTeam_row3'>
                    <div className='col-lg-5 col-md-6 col-sm-12 JoinOurTeam_content1 d-flex flex-column justify-content-center'>
                        <h2 className="h2-responsive">Our Vision</h2>
                        <p className="my-4 text-justify">
                            To help deliver innovation, impact, and inclusivity in a globalized world.
                        </p>
                        <a href="/Candicateprofile" className='JoinOurTeam_Button btn btn-primary'>Get Started <i className="bi bi-arrow-up-right"></i></a>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12'>
                        <img src={img2} alt="" className='JoinOurTeam_image img-fluid mb-4 mb-md-0'/>
                    </div>
                </div>

                <div className='row my-5 JoinOurTeam_row4'>
                    <div className='col-lg-5 col-md-6 col-sm-12'>
                        <img src={img3} alt="" className='JoinOurTeam_image img-fluid mb-4 mb-md-0'/>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-12 JoinOurTeam_content1 d-flex flex-column justify-content-center'>
                        <h2 className="h2-responsive">Our Mission</h2>
                        <p className="my-4 text-justify">
                            To help our clients build enduring culture, practices, and systems in their global operations, while building a firm that attracts and motivates exceptional talent.
                        </p>
                        <a href="/Candicateprofile" className='JoinOurTeam_Button btn btn-primary'>Apply Now <i className="bi bi-arrow-up-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinOurTeam;
