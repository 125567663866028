import React from "react";
import Header from "../../Components/Header/Header";
import CloudOverview from "../../Components/Services/Cloud/CloudOverview/CloudOverview";
import CloudCapabilities from "../../Components/Services/Cloud/CloudCapabilities/CloudCapabilities";
import CloudTools from "../../Components/Services/Cloud/CloudTools/CloudTools";
import CloudCaseStudies from "../../Components/Services/Cloud/CloudCaseStudies/CloudCaseStudies";
import CloudBlogs from "../../Components/Services/Cloud/CloudBlogs/CloudBlogs";
import Footer from '../../Components/Footer/Footer'

const Cloud = () => {
  return (
    <>
      <Header />
      <CloudOverview />
      <CloudCapabilities />
      <CloudTools />
      <CloudCaseStudies />
      <CloudBlogs />
      <Footer/>
    </>
  );
};

export default Cloud;
