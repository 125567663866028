import React from 'react'
import AppBLog from '../../Components/Services/AppDevelopment/AppBlog/AppBLog'
import Header from '../../Components/Header/Header'
import AppOverview from '../../Components/Services/AppDevelopment/AppOverview/AppOverview'
import APPTools from '../../Components/Services/AppDevelopment/AppTools/APPTools'
import AppCapabilities from '../../Components/Services/AppDevelopment/AppCapabilities/AppCapabilities'
import AppCasestudies from '../../Components/Services/AppDevelopment/AppCasestudies/AppCasestudies'
import Footer from '../../Components/Footer/Footer'

const AppDevelopment = () => {
  return (
    <div>
        <Header/>
        <AppOverview/>
        <AppCapabilities/>
        <APPTools/>
        <AppCasestudies/>
      <AppBLog/>
       <Footer/>
    </div>
  )
}

export default AppDevelopment;
