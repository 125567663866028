import React from "react";
import "./InsuranceOverview.css";

const InsuranceOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="Content_Development2 text-center">
            <h1>INSURANCE</h1>
            <p>
              Harness the power of digital technology to reimagine your
              insurance business
            </p>
          </div>
        </div>
      </div>

      <div className="row my-4">
        <div className="col-12 d-flex flex-wrap justify-content-center">
          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
          </div>

          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Services")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Services
            </button>
          </div>

          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Case Studies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
          </div>

          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Connect")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Connect
            </button>
          </div>

          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
          </div>
        </div>
      </div>
          
      <div className="text-info text-center">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-12 my-5 Overview_content">
          <p>
            Both property and life insurance industries are facing a wave of new
            digital technologies, and traditional insurance companies are
            feeling pressure to adapt to a rapidly changing market that values
            agility.
          </p>
          <p>
            The inherent inefficiency in the traditional insurance value chain
            and an excessive dependence on manual processes is making insurance
            innovation difficult and insurance products expensive. InsurTech
            companies, which use technology to streamline the insurance process
            and offer more innovative products, have been at the forefront of
            this shift.
          </p>
          <p>
            Overall, the adoption of digital technologies is helping to make the
            insurance industry more efficient, transparent, and
            customer-centric, which is ultimately good for consumers.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InsuranceOverview;
