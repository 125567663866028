import React from "react";
import "./TeleComService.css";

const TeleComService = () => {
  return (
    <div className="container" id="Services">
      <div className="row">

      <div className="col-lg-12 col-md-12 col-sm-12">
      <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 BankServices_Content">
            <h3>TELECOMMUNICATION SERVICES </h3>
            <p>
              We collaborate closely with clients across the TeleComService to
              help them meet today’s challenges while preparing for what’s
              ahead.
            </p>
            <p>
              telecoms service provider in Brighton. As a telecoms provider, we
              offer a variety of both on-premises and hosted telecoms solutions.
              Our skilled telecoms engineers can travel to your site to complete
              any required installations professionally and to the highest
              safety standards.
            </p>
            <p>
              On-premises telecoms solutions are perfect for businesses that
              want to maintain as much control over the core components of their
              telecommunications infrastructure. Customers looking for an
              alternative may wish to see our selection of hosted VoIP options.
            </p>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="row d-flex flex-row">

            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="mobile1 p-3 d-flex flex-column justify-content-center align-items-center">
                <h3 className="txt_01">
                  Public Switched Telephone Networks (PSTN)
                </h3>
                <p className="txt_02">
                  Compatibility with legacy systems that cannot be integrated
                  with digital services.
                  <br />
                </p>
              </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="mobile2 p-3 d-flex flex-column justify-content-center align-items-center">
                <h3 className="txt_01">5G Broadband</h3>
                <p className="txt_02">
                  Network providers are working quickly to upgrade existing the
                  telecoms infrastructure.
                  <br />
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="mobile4 p-3 d-flex flex-column justify-content-center align-items-center">
                <h3 className="txt_01">Multiprotocol Label Switching (MPLS)</h3>
                <p className="txt_02">
                  Technique for routing data between multiple locations using a
                  system of short path labels as opposed to longer network
                  addresses.
                  <br />
                </p>
              </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="mobile5 p-3 d-flex flex-column justify-content-center align-items-center">
                <h3 className="txt_01">SIP Trunks</h3>
                <p className="txt_02">
                  Flexible and more cost-effective alternative to ISDN lines for
                  both inbound and outbound calls
                  <br />
                </p>
              </div>
            </div>

          </div>
        </div>

        <div className="col-lg-12">
          <div className=" BankServices_Content"></div>
        </div>

      </div>
    </div>
    </div>
    </div>
  );
};

export default TeleComService;
