import React from 'react'
import Header from '../../Components/Header/Header'
import DataOverview from '../../Components/Services/DataAnalyst/DataOverview/DataOverview'
import DataCapabilities from '../../Components/Services/DataAnalyst/DataCapabilities/DataCapabilities'
import DataTools from '../../Components/Services/DataAnalyst/DataTools/DataTools'
import DataCaseStudies from '../../Components/Services/DataAnalyst/DataCaseStudies/DataCaseStudies'
import DataBlogs from '../../Components/Services/DataAnalyst/DataBlogs/DataBlogs'
import Footer from '../../Components/Footer/Footer'

const DataandAnalyst = () => {
  return (
   <>
   <Header/>
   <DataOverview/>
   <DataCapabilities/>
   <DataTools/>
   <DataCaseStudies/>
   <DataBlogs/>
   <Footer/>
   </>
  )
}

export default DataandAnalyst
