import React from 'react';
import "./Aboutcompany.css";
import aboutimg from "../../Components/Aboutus/Assests/01.jpeg";

const Aboutcompany = () => {
  return (
    <div className='container mt-0'>
      <div className='row py-3'>
        <div className='d-flex flex-column flex-lg-row overall_Content'>
          <div className='col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center align-items-center image_ooo1'>
            <img src={aboutimg} alt="" />
          </div>
          <div className='col-sm-12 col-md-12 col-lg-8 d-flex flex-column justify-content-center Content_item1'>
            <h1>Lab of Digital <span> Applications </span></h1>
            <ul>
              <li>We began in the year 2018 as a Software Development Company</li>
              <li>60 Highly skilled and trained team working on latest and greatest technology</li>
              <li>360º View of the customer requirement enabling high-quality delivery</li>
              <li>Chennai and Bangalore Headquartered in prime IT sector segment</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='row py-3 d-flex flex-row'>
        <div className='col-12 col-md-6 d-flex flex-column align-items-center'>
          <div className='color_round1 d-flex justify-content-center align-items-center'>
            <h1>Vision</h1>
          </div>
          <div className='color_roundh4 text-center'>
              <h5> "To be the world's leading provider of innovative
              technology solutions that empower businesses to
              achieve digital transformation and drive global
              progress."</h5>
          </div>
        </div>

        <div className='col-12 col-md-6 d-flex flex-column align-items-center'>
          <div className='color_round2 d-flex justify-content-center align-items-center'>
            <h1>Mission</h1>
          </div>
          <div className='color_roundh4 text-center'>
             <h5> "Our mission is to deliver cutting-edge IT
              solutions that enhance productivity,
              foster innovation, and ensure sustainable
              growth for our clients. We are committed
              to excellence, integrity, and continuous
              improvement, leveraging our expertise to
              solve complex challenges and create
              value for our stakeholders."</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutcompany;
