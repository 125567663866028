import React from "react";
import "./Banner.css";
import img1 from "../../Components/Banner/Assets/2.jpg";
import img2 from "../../Components/Banner/Assets/3.jpg";
import img3 from "../../Components/Banner/Assets/4.jpg";

const Banner = () => {
  return (
    <div id="carouselExampleAutoplaying" className="carousel slide overall_01"data-bs-ride="carousel">
      <div className="carousel-inner overall_02">

         <div className="carousel-item active back_color1">
           <div className="row d-flex flex-row align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h1 className="title">
                Accelerate Life Science business growth with Us.
              </h1>
              <p className="paragraph">
              Ldapp solutions provide specialized information technology products and services to its clients across the globe. Our expert teams of analysts, application developers, UX, and web designers take pride in developing a gamut of products for our global clientele.              </p>
              <div>
                {/* <button type="button" className="btn2 btn btn-primary">
                  Learn More <i className="bi bi-arrow-right"></i>
                </button> */}
              </div>
            </div>
            <div className="col-12 col-md-6 text-center image">
              <img
                src={img1}
                alt="Banner 1"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <div className="carousel-item back_color2">
          <div className="row d-flex flex-row align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h1 className="title">
                Accelerate Life Science business growth with Us.
              </h1>
              <p className="paragraph">
              We consistently strive to integrate latest and emerging technologies into our services. Ldapp solutions  boasts a team of technical, functional and project delivery managers with pertinent expertise, equipped to DESIGN , DEVELOP & IMPLEMENT solutions and services of any complexity.

            </p>
              <div>
                {/* <button type="button" className="btn2 btn btn-primary">
                  Learn More <i className="bi bi-arrow-right"></i>
                </button> */}
              </div>
            </div>
            <div className="col-12 col-md-6 text-center image">
              <img
                src={img2}
                alt="Banner 2"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <div className="carousel-item back_color3">
          <div className="row d-flex flex-row align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h1 className="title">
                Accelerate Life Science business growth with AI-powered Analytics
              </h1>
              <p className="paragraph">
                On the journey from 'Molecule to Medicine' we fast-track innovation, unlocking the transformative power of data with Generative AI. This speeds growth, revolutionizes Pharmaceutical Organizations, optimizing Drug Discovery to Drug Commercialization.
              </p>
              <div>
                <button type="button" className="btn2 btn btn-primary">
                  Learn More <i className="bi bi-arrow-right"></i>
                </button>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center image">
              <img
                src={img3}
                alt="Banner 3"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Banner;
