import React from "react";
import "./ECommerceOverview.css";

const ECommerceOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="Content_Development16">
            <h1>E-COMMERCE</h1>
            <p>
              Solutions for e-commerce and online sales including e-commerce
              analytics,
              <br />
              conversion rate optimisation, catalogue management, logistic
              management,
              <br /> loyalty programs as well as return and refund management.
            </p>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12 d-flex flex-wrap justify-content-center">
          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Services")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Services
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Case Studies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Connect")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Connect
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
            </div>

        </div>
      </div>
      <div className="text-info text-center">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-12 my-5 Overview_content">
          <p>
            E-commerce is a rapidly evolving field that has transformed the way
            businesses operate and consumers shop. By leveraging technology and
            innovative strategies, businesses can effectively tap into the vast
            opportunities offered by the online marketplace. Understanding the
            key components, benefits, challenges, and future trends is essential
            for success in this dynamic industry.
          </p>
          <p>
            Prominent examples of successful e-commerce businesses are Amazon,
            Alibaba, Warby Parker, and Shopify, which have utilized innovative
            strategies to achieve significant market presence. Future trends in
            e-commerce include mobile commerce, AI and machine learning,
            augmented reality, voice commerce, and a focus on sustainability.
            Overall, e-commerce continues to evolve, offering vast opportunities
            for businesses to thrive in the digital marketplace.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ECommerceOverview;
