import React from "react";
import "./CloudCaseStudies.css";
import img1 from "../../Webapplication/CaseStudies/Assets/1 (1).jpeg";
import img2 from "../../Webapplication/CaseStudies/Assets/2.jpeg";
import img3 from "../../Webapplication/CaseStudies/Assets/3.jpeg";

const CloudCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudy" id="CaseStudies">

       <div className="row">
        <div className="col-lg-12 heading text-center">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        </div>

        <div className="row">

        <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img1}
              alt="img1"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_1 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>RELATIONSHIP MANAGEMENT WORKBENCH FOR BANK</h5>
              <p>
              LDAPP SOLUTIONS was on-boarded to replace legacy CRM platforms & current framework with a one stop solution “RM Workbench 2.0” to provide a 360 degree holistic customer viewpoint.
 </p>
            </div>
          </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img2}
              alt="img2"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_2 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                CORE BANKING APPLICATIONS DEVELOPMENT, TESTING & SUPPORT CENTRE
              </h5>
              <p>
              The bank provides services such as insurance, asset management, stock broking, nominee services, venture capital, leasing, and Internet banking.
              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img3}
              alt="img3"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_3 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>COMMODITY CONTROL APPLICATION IMPLEMENTATION</h5>
              <p>
              Shared network drive is used as backup mechanism with limited access controls and audit..
              </p>
            </div>
          </div>
          </div>

        </div>
      </div>
    
  );
};

export default CloudCaseStudies;
