import Home from "./Pages/Home"
import Lab from "./Pages/Lab/Lab"

import { BrowserRouter, Routes, Route } from "react-router-dom";
import LifeScience from "./Pages/LifeScience/LifeScience";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Peoplecarear from "./Pages/Peoplecarear/Peoplecarear";
import Webdevelopment from "./Pages/Services/Webdevelopment"
import DataandAnalyst from "./Pages/Services/DataandAnalyst"
import Mobile from "./Pages/Services/Mobile";
import AI from "./Pages/Services/AI";
import Business from "./Pages/Services/Business";
import Cloud from "./Pages/Services/Cloud";
import Devops from "./Pages/Services/Devops";
import Digital from "./Pages/Services/Digital";
import Testing from "./Pages/Services/Testing";
import Webenterprice from "./Pages/Services/Appdevelopment";
import Banking from "./Pages/Industries/Banking";
import Insurance from "./Pages/Industries/Insurance";
import HT  from "./Pages/Industries/HT";
import Public from "./Pages/Industries/Public";
import Retail from "./Pages/Industries/Retail";
import Global  from "./Pages/Industries/Global";
import Energy  from "./Pages/Industries/Energy";
import Healthcare from "./Pages/Industries/Healthcare";
import ECommerce  from "./Pages/Industries/ECommerce";
import TeleCom    from "./Pages/Industries/TeleCom";
import Candicateprofile from "./Pages/Candicateprofile/Candicateprofile";
import ContactUs1 from "./Pages/ContactUs1/ContactUs1";
import AppDevelopment from "./Pages/Services/Appdevelopment";

const App = () => {
  return (
 

<div>
<BrowserRouter>
   <Routes>
     <Route path="/" element={<Home />} />
     <Route path="/Lab" element={<Lab />} />
     <Route path="/LifeScience" element={<LifeScience />} />
     <Route path="/AboutUs" element={<AboutUs />} />   
     <Route path="/Peoplecarear" element={<Peoplecarear />} /> 
     <Route path="/Webdevelopment" element={<Webdevelopment />} />   
     <Route path="/DataandAnalyst" element={<DataandAnalyst />} />   
     <Route path="/Mobile" element={<Mobile />} /> 
     <Route path="/AI" element={<AI />} />   
     <Route path="/Business" element={<Business />} />   
     <Route path="/Cloud" element={<Cloud />} />   
     <Route path="/Devops" element={<Devops />} />   
     <Route path="/Digital" element={<Digital/>} />   
     <Route path="/Testing" element={< Testing/>} />  
     <Route path="/Webenterprice" element={< Webenterprice/>} /> 
     <Route path="/Banking" element={< Banking/>} />
     <Route path="/Insurance" element={< Insurance/>} />
     <Route path="/HT" element={< HT/>} />
     <Route path="/Public" element={< Public/>} />
     <Route path="/Retail" element={< Retail/>} />
     <Route path="/Global" element={< Global/>} />
     <Route path="/Energy" element={<Energy />} />
     <Route path="/Healthcare" element={<Healthcare />} />
     <Route path="/TeleCom" element={<TeleCom />} />
     <Route path="/ECommerce" element={<ECommerce/>} />
     <Route path="/Candicateprofile" element={<Candicateprofile/>} />
     <Route path="/ContactUs1" element={<ContactUs1/>} />
     <Route path="/AppDevelopment" element={<AppDevelopment/>} />
  
   </Routes>
 </BrowserRouter>



</div>
 
  )
}

export default App
