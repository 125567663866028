import React from 'react'
import Header from '../../Components/Header/Header'
import AIOverview from '../../Components/Services/AI/AIOverview/AIOverview'
import AICapabilities from '../../Components/Services/AI/AICapabilities/AICapabilities'
import AITools from '../../Components/Services/AI/AITools/AITools'
import AICaseStudies from '../../Components/Services/AI/AICaseStudies/AICaseStudies'
import ALBlogs from '../../Components/Services/AI/AIBlogs/ALBlogs'
import Footer from '../../Components/Footer/Footer'

const AI = () => {
  return (
    <>
    <Header/>
    <AIOverview/>
    <AICapabilities/>
    <AITools/>
    <AICaseStudies/>
      <ALBlogs/>
      <Footer/>
    </>
  )
}

export default AI
