import React from "react";
import "./BusinessOverview.css";

const BusinessOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="BMContent_Development6">
            <h1>BUSINESS PROCESS MANAGEMENT</h1>

            <p>
              Streamlining for success: Elevating efficiency and enhancing
              accuracy
            </p>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-12 my-3">
          <div className="BMButton_Contain">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Capabilities")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Capabilities
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Tools")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Tools
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("CaseStudies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
          </div>
        </div>
      </div>
      <div className="BMtext-info">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-lg-12 my-5 BMOverview_content">
          <p>
            We help clients accelerate digital operational excellence. With our
            deep functional and technical expertise, we help enterprises in
            decision-making, deployment and modernize their existing processes.
            We are focused on building robust, scalable and innovative solutions
            catering to different industry specific requirements
          </p>
          <p>
            There are several key factors that can impact the accuracy and
            efficiency of business processes, including: Clear and well-defined
            process step, accuracy, continuous improvement, standardization,
            measurement and tracking.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BusinessOverview;
