import React from "react";
import "./PublicSectorCaseStudies.css";
import img1 from "../PublicSectorCaseStudies/Assets/PublicCase1.jpg"

const PublicSectorCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudy">
      
      <div className="row">
        <div className="col-12 heading text-center">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        </div>

        <div className='row'>
          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img1}
              alt="img1"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_1 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                ORGANIZATION WIDE DIGITALIZATION THROUGH LDAPP’S DELIVERY
                CENTRES TO SUPPORT
              </h5>
              <p>
              The security system that enables working for Citizens and Permanent Residents to set aside funds for retirement. It also addresses healthcare, home ownership, family protection and asset enhancement. </p>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};

export default PublicSectorCaseStudies;
