import React from "react";
import "./InsuranceCaseStudies.css";

import img1 from "../InsuranceCaseStudies/Assets/InsuranceCase1.jpg"
const InsuranceCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudy" id="Case Studies">
      
      <div className="row">
        <div className="col-lg-12 heading text-center">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        </div>
        
        <div className='row'>
         
          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img1}
              alt="img1"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_1 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                ROBOTICS PROCESS AUTOMATION IN UNDERWRITING FOR AN INSURANCE
                COMPANY
              </h5>
              <p>
              looking to drive the process efficiency and business outcome in the Fleet handling of NB and application renewal processes. The process efficiency gained through RPA implementation was expected in the firm’s PIC partnership process and Claims processing.
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};

export default InsuranceCaseStudies;
