import React, { useEffect } from 'react';
import './Fastfact.css';
import img1 from "../../Components/Fastfact/Assets/1.png";
import img2 from "../../Components/Fastfact/Assets/2.png";
import img3 from "../../Components/Fastfact/Assets/3.png";
import img4 from "../../Components/Fastfact/Assets/4.png";
import img5 from "../../Components/Fastfact/Assets/5.png";
import img6 from "../../Components/Fastfact/Assets/6.png";
import img7 from "../../Components/Fastfact/Assets/7.png";
import img8 from "../../Components/Fastfact/Assets/8.png";
import img9 from "../../Components/Fastfact/Assets/9.png";
import img10 from "../../Components/Fastfact/Assets/10.png";
import img11 from "../../Components/Fastfact/Assets/11.png";
import img12 from "../../Components/Fastfact/Assets/12.png";
import img13 from "../../Components/Fastfact/Assets/13.png";
import img14 from "../../Components/Fastfact/Assets/14.png";
import img15 from "../../Components/Fastfact/Assets/15.png";
import img16 from "../../Components/Fastfact/Assets/16.png";
import img17 from "../../Components/Fastfact/Assets/17.png";
import img18 from "../../Components/Fastfact/Assets/18.png";
import img19 from "../../Components/Fastfact/Assets/19.png";
import img20 from "../../Components/Fastfact/Assets/20.png";
import img21 from "../../Components/Fastfact/Assets/21.png";
import img22 from "../../Components/Fastfact/Assets/22.png";
import img23 from "../../Components/Fastfact/Assets/23.png";
import img24 from "../../Components/Fastfact/Assets/24.png";

const images = [
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12,
    img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24
];

const Fastfact = () => {
    useEffect(() => {
        const imageElements = document.querySelectorAll('.Technology_User img');
        imageElements.forEach((img, index) => {
            setTimeout(() => {
                img.classList.add('rotate');
            }, index * 1000); // Adjust the delay as needed
        });
    }, []);

    return (
        <div className='container Value_all'>
            <div className='row'>
                <div className='col-lg-12 Value_all11 text-center'>
                    <h1>TECHSTACK</h1>
                    <span>OPEN SOURCE ENTERPRISE TECHNOLOGY</span>
                </div>
                <div className='col-12 Technology_User'>
                    <div className='row d-flex flex-row'>
                        {images.map((src, index) => (
                            <div key={index} className='col-6 col-md-4 col-lg-2 text-center'>
                                <img src={src} alt={`Fastfact ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Fastfact;
