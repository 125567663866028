import React from "react";
import Header from "../../Components/Header/Header";
import Overview from "../../Components/Services/Webapplication/Overview/Overview";
import Capabilities from "../../Components/Services/Webapplication/Capabilities/Capabilities";
import CaseStudies from "../../Components/Services/Webapplication/CaseStudies/CaseStudies";
import Tools from "../../Components/Services/Webapplication/Tools/Tools";
import Blogs from "../../Components/Services/Webapplication/Blogs/Blogs";
import Footer from "../../Components/Footer/Footer"

const Webdevelopment = () => {
  return (
    <>
      <Header />
      <Overview />
      <Capabilities/>
     
      <Tools/>
      <CaseStudies/>
      <Blogs/>
      <Footer/>

    </>
  );
};

export default Webdevelopment;
