import React from "react";
import "./GlobalStudies.css";
import img1 from "../GlobalCaseStudies/Assets/global2.jpg";
import img2 from "../GlobalCaseStudies/Assets/Global3.jpg";
import img3 from "../GlobalCaseStudies/Assets/Global4.jpg";

const GlobalStudies = () => {
  return (
    <div className="container-fluid container_CaseStudy">
      
      <div className="row">
        <div className="col-12 heading text-center">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        </div>

        <div className='row d-flex flex-row'>
        <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img1}
              alt="img1"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_1 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>FM DASHBOARD FOR REGULATORY COMPLIANCE</h5>
              <p>
              LDAPP played a vital role in planning & building next generation platform for both web and mobile app to automate end to end FM Supervisory functions for ensuring Traders Regulatory Compliance
              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img2}
              alt="img2"
              className="img1"
            />
            <div className="lay">
              <div className="icon_2 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>TRADE FINANCE TRANSFORMATION</h5>
              <p>
              Delivered several functionalities such as Trade-AML, Name-screening, Sanctions, Trade Asset Management, Trade MIS & Analytics, Cross-sell & Up-sell Opportunities, TradeLimits/Facilities, Trade Mobile & Channels
              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img3}
              alt="img3"
              className="img1"
            />
            <div className="lay">
              <div className="icon_3 mb-4">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>TRADE FINANCE TRANSFORMATION</h5>
              <p>
              Together with Optimum, bank executed trade finance transformation program developing 3 enterprise scale platforms to provide a consistent hassle free TF offerings
              </p>
            </div>
          </div>
        </div>
    
    </div>

    </div>
  );
};

export default GlobalStudies;
