import React from 'react';
import './BankCaseStudies.css';
import img1 from "../../Banking/BankCaseStudies/Assets/1 (1).jpeg";
import img2 from "../../Banking/BankCaseStudies/Assets/2.jpeg";
import img3 from "../../Banking/BankCaseStudies/Assets/3.jpeg";

const BankCaseStudies = () => {
  return (
    <div className='container-fluid container_CaseStudy'>
      
      <div className='row'>
        <div className='col-12 heading text-center'>
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
      </div>
      
      <div className='row d-flex flex-row'>
        <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img 
            src={img1} alt="img1" 
            className="img1" 
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_1 mb-2">
                <i className="bi bi-file-pdf-fill"></i> E BOOK
              </div>
              <h5>FM DASHBOARD FOR REGULATORY COMPLIANCE</h5>
              <p>
                LDAPP SOLUTIONS played a vital role in planning & building next generation platform for both web and mobile app to automate end to end FM Supervisory functions for ensuring Traders Regulatory Compliance.
              </p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img src={img2} 
            alt="img2" 
            className="img1" />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_2 mb-2">
                <i className="bi bi-file-pdf-fill"></i> E BOOK
              </div>
              <h5>TRADE FINANCE TRANSFORMATION</h5>
              <p>
                Legacy platforms with single application for deployment created huge resistance to new developments in case of organizational changes and/or business reengineering.
              </p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img src={img3} alt="img3" className="img1" />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_3 mb-2">
                <i className="bi bi-file-pdf-fill"></i> E BOOK
              </div>
              <h5>NEXT GEN DIGITAL APPLICATION DEVELOPMENT AND TESTING FOR A MIDDLE EAST BANK</h5>
              <p>
                It is a leading financial institution with an expanding footprint across the Middle East with a strong presence
                in the financial capitals of the world.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankCaseStudies;
