import React from "react";
import Header from "../../Components/Header/Header";
import BusinessOverview from "../../Components/Services/Business/BusinessOverview/BusinessOverview";
import BusinessCapabilities from "../../Components/Services/Business/BusinessCapabilities/BusinessCapabilities";
import BusinessTools from "../../Components/Services/Business/BusinessTools/BusinessTools";
import BusinessStudies from "../../Components/Services/Business/BusinessStudies/BusinessStudies";
import BusinessBlogs from "../../Components/Services/Business/BusinessBlogs/BusinessBlogs";
import Footer from '../../Components/Footer/Footer'

const Business = () => {
  return (
    <>
      <Header />
      <BusinessOverview />
      <BusinessCapabilities />
      <BusinessTools />
      <BusinessStudies />
      <BusinessBlogs />
      <Footer/>
    </>
  );
};

export default Business;
