import React from "react";
import "./DigitalCustomerCaseStudies.css";
import img1 from "../../DigitalCustomer/DigitalCustomerCaseStudies/Assets/CxCase1.jpg";
import img2 from "../DigitalCustomerCaseStudies/Assets/CxCase2.jpg";

const DigitalCustomerCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudy" id="CaseStudies">

       <div className="row">
        <div className="col-12 heading text-center">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        </div>


        <div className="row">
        <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img1}
              alt="img1"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_1 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                PAYMENT INTEGRATION SERVICES FOR A GLOBAL PAYMENT SERVICE
                PROVIDER
              </h5>
              <p>
              It establishes the debit network and drive the adoption of electronic payments in  with the support of major banks functioning .


              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img2}
              alt="img2"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_2 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>
                MOBILE APPLICATION DEVELOPMENT FOR A REGIONAL BANK 
              </h5>
              <p>
              The digital banking program in delivering multiple cells which
comprises of mobile App and internet banking solution. The scope of service included design, build, test
and implementation services in multi squad and multi-phase approach..
              </p>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default DigitalCustomerCaseStudies;
