import React from "react";
import "./RetailService.css";

const RetailService = () => {
  return (
    <div className="container" id="Services">
      <div className="row">


      <div className="col-lg-12 col-md-12 col-sm-12">
      <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 BankServices_Content">
            <h3>DIGITAL SERVICES FOR RETAIL</h3>
            <p>
              LDAPP focuses on building the enhanced consumer experience
              through our innovative approach in delivering cloud-enabled
              platform solutions, business process automation and strategizing
              the omnichannel commerce strategy (right mix of stores, mobile,
              digital marketing and CRM capabilities)
            </p>
            <p>
              With our deep experience in Salesforce CRM enablement, cloud
              migration services and omnichannel enablement, we have enabled
              leading retail and marketplace enterprises to embrace digital
              disruption
            </p>
          </div>

          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="row d-flex flex-row">

            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="mobile1 p-3 d-flex flex-column justify-content-center align-items-center">
                <h3 className="txt_01">SUPPLY CHAIN VISIBILITY & ANALYTICS</h3>
                <p className="txt_02">
                  Optimize operations, identify bottlenecks and inefficiencies,
                  and improve decision-making.
                  <br />
                </p>
              </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                  <div className="mobile2 p-3 d-flex flex-column justify-content-center align-items-center">
                <h3 className="txt_01">PRODUCTS AND SOLUTIONS</h3>
                <p className="txt_02">
                  Improve CX through Point-of-sale (POS) systems, mobile
                  applications, CRM and payment platforms
                  <br />
                </p>
              </div>
            </div>

            <div className="col-12">
                  <div className="mobile3 p-3 d-flex flex-column justify-content-center align-items-center">
              <h3 className="txt_01">TESTING AND CX</h3>
              <p className="txt_02">
                Gather customer feedback and identify areas for improvement to
                improve customer satisfaction.
                <br />
              </p>
            </div>
            </div>

          </div>
        </div>

      </div>
    </div>
    </div>
    </div>
  );
};

export default RetailService;
