import React from "react";
import "./DataOverview.css";

const DataOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="DAContent_Development4">
            <h1>DATA AND ANALYTICS</h1>
            <p>
            Data is the back bone for actionable insights
            </p>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-12 my-3">
          <div className="DAButton_Contain">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Capabilities")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Capabilities
            </button>
            <button
             onClick={() => {
              document
                .getElementById("Tools")
                .scrollIntoView({ behavior: "smooth" });
            }}>Tools</button>
            <button
             onClick={() => {
              document
                .getElementById("CaseStudies")
                .scrollIntoView({ behavior: "smooth" });
            }}>Case Studies</button>
            <button
             onClick={() => {
              document
                .getElementById("Blogs")
                .scrollIntoView({ behavior: "smooth" });
            }}>Blogs</button>
          </div>
        </div>
      </div>
      <div className="DAtext-info">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-lg-12 my-5 DAOverview_content">
          <p>
          Organizations rely heavily on data today: to make well-informed decisions faster, minimize risks, maximize profits, and, importantly, acquire and retain new customers. A small increase in customer retention can lead to significant cost savings.
          </p>
          <p>
          For example, if a business has a customer retention rate of 50%, a 2% increase in retention (to 52%) would result in a 4% increase in overall revenue, since the business would be retaining more of its existing customer base. This revenue increase would likely be accompanied by a corresponding decrease in marketing and customer acquisition costs, resulting in a net cost savings for the business.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DataOverview;
