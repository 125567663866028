import React from "react";
import "./BusinessStudies.css";
import img1 from "../../Business/BusinessStudies/Assets/BusinesCase1.jpg";
import img2 from "../../Business/BusinessStudies/Assets/pic1.jpg";

const BusinessStudies = () => {
  return (
    <div className="container-fluid container_CaseStudy" id="CaseStudies">
      
      <div className="row">
        <div className="col-12 heading text-center">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        </div>

        <div className="row">

        <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img1}
              alt="img1"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_1 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>BACK OFFICE 24 X7 SUPPORT CENTRE FOR GLOBAL OPERATIONS</h5>
              <p>
                
              The bank is a major global financial services provider engaged in retail banking, credit cards, corporate and investment banking and wealth management.
              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img2}
              alt="img2"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_2 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>PROCESS AUTOMATION USING UI PATH FOR AN AUTOMOTIVE GIANT</h5>
              <p>
              LDAPP SOLUTIONS being one of leading IT service providers, strongly believes that it would be an ideal partner to Daimler to provide the RPA managed services delivery centre leveraging LDAPP’s RPA practice centre methodologies and capabilities
              </p>
            </div>
          </div>
        </div>

      </div>
      </div>
    
  );
};

export default BusinessStudies;
