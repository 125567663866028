import React from "react";
import "./HealthcareCaseStudies.css";
import img1 from "../../Healthcare/HealthcareCaseStudies/Assets/MobileCase1.webp";
import img2 from "../../Banking/BankCaseStudies/Assets/2.jpeg";

const HealthcareCaseStudies = () => {
  return (
    <div className="container-fluid container_CaseStudy">

      <div className="row">
        <div className="col-lg-12 heading text-center">
          <h1>LEADING OUR WAY</h1>
          <p>CASE STUDIES</p>
        </div>
        </div>

        <div className='row d-flex flex-row'>

          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img1}
              alt="img1"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_1 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>Consumer Healthcare App Development</h5>
              <p>
              Delivering World class healthcare Software Development Services Compliant with globally accepted Standardizations for
               the World's Leading fitness and HealthCare Brands. 
               </p>
            </div>
          </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12 mb-4'>
          <div className="alll">
            <img
              src={img2}
              alt="img2"
              className="img1"
            />
            <div className="lay d-flex flex-column justify-content-center align-items-center text-center">
              <div className="icon_2 mb-2">
                <i className="bi bi-file-pdf-fill"></i>E BOOK
              </div>
              <h5>Clinical Applications Development</h5>
              <p>
                We have Experience creating  and Modifiying Software as the Foundation for everday healthcareoperations.
                ourProducts ensure the integrity of clinical Procedures and aid in improving patients treatment.
              </p>
            </div>
          </div>

        </div>
        </div>

      </div>
  );
};

export default HealthcareCaseStudies;
