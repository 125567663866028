import React from 'react'
import "./AppOverview.css"

const AppOverview = () => {
  return (
    <div>
         <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="APPContent_Development18">
            <h1> Application Development and Maintenance </h1>
            <p>
            Product Development
            </p>
          </div>
        </div>
      </div>
      <div className="row my-2">
        <div className="col-lg-12 my-3">
          <div className="APPButton_Contain">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            <button
              onClick={() => {
                document
                  .getElementById("Capabilities")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Capabilities
            </button>
            <button
             onClick={() => {
              document
                .getElementById("Tools")
                .scrollIntoView({ behavior: "smooth" });
            }}>Tools</button>
            <button
             onClick={() => {
              document
                .getElementById("CaseStudies")
                .scrollIntoView({ behavior: "smooth" });
            }}>Case Studies</button>
            <button
             onClick={() => {
              document
                .getElementById("Blogs")
                .scrollIntoView({ behavior: "smooth" });
            }}>Blogs</button>
          </div>
        </div>
      </div>
      <div className="APPtext-info">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-lg-12 my-5 APPOverview_content">
          <p>
           App development is rapidly growing. From retail, telecommunications and e-commerce to insurance, healthcare and government, organizations across industries must meet user expectations for real-time, convenient ways to conduct transactions and access information          </p>
          <p>
          Delivering a great user experience is vital. That starts with understanding that your user interface for a app development should be simpler than a desktop application interface. By creating a straightforward UX design that is focused on critical functions, you can provide a better user experience while consuming fewer resources.          </p>
        </div>
      </div>
    </div>
      
    </div>
  )
}

export default AppOverview
