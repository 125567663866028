import React from "react";
import "./RetailOverview.css";

const RetailOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="Content_Development11">
            <h1>RETAIL</h1>

            <p>
              Power your retail and emerging business success
              <br />
              with cutting-edge tech solutions
            </p>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12 d-flex flex-wrap justify-content-center">
          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Services")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Services
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Case Studies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Connect")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Connect
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
          </div>

        </div>
      </div>
      <div className="text-info text-center">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-lg-12 my-5 Overview_content">
          <p>
            The retail industry is witnessing a seismic shift. Rapidly changing
            customer expectations along with evolving technology and emerging
            digital firms are driving widespread disruption in the industry.
          </p>
          <p>
            Consumers now expect a more enriched shopping experience,
            personalized service and valued offerings. Additionally,
            advancements in the field of Artificial Intelligence, Augmented
            Reality and outcome-driven digital platforms have revolutionized the
            way retail sector operates. Also, the increasing focus on data and
            expansion of sales and marketing channels (like mobile and social
            commerce) has brought retail to a tipping point. This makes it
            imperative for businesses to showcase agility in their business
            operations and customer service models.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RetailOverview;
