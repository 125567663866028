import React from "react";
import "./HealthcareOverview.css";

const HealthcareOverview = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="Content_Development13">
            <h1>Custom Healthcare App Development Company</h1>

            <p>
              Delivering world-class healthcare software development services
              compliant with
              <br /> globally accepted standardizations for the world's leading
              fitness and healthcare brands
            </p>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-lg-12 d-flex flex-wrap justify-content-center">
          <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Overview")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Overview
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Services")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Services
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Case Studies")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Case Studies
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Connect")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Connect
            </button>
            </div>

            <div className="Button_Contain my-2">
            <button
              onClick={() => {
                document
                  .getElementById("Blogs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Blogs
            </button>
          </div>
        </div>
      </div>
      <div className="text-info text-center">
        <hr />
      </div>
      <div className="row my-3" id="Overview">
        <div className="col-12 my-5 Overview_content">
          <p>
            LDAPP is a leading custom healthcare providing technology solutions
            and services for healthcare organizations and practitioners,
            assisted living facilities, nursing homes, diagnostic and research
            centers, medical ISVs, and device manufacturers across the globe.
            With our robust and highly customizable IT solutions that bring
            value to the client’s businesses, we empower medical institutions
            with high healthcare interoperability, effective patient engagement,
            accurate diagnostics, and positive healthcare outcomes
          </p>
          <p>
            With a mission to provide technological advancements to enterprises
            via innovative solutions, our learned team of professionals with
            years of expertise in software development services for healthcare
            and pharmaceuticals works hand in hand with institutions,
            understanding their needs and delivering research-driven
            personalized solutions
          </p>
        </div>
      </div>
    </div>
  );
};

export default HealthcareOverview;
